import { createApp } from 'vue'
import App from './App.vue'
import volt from './volt'

if(volt.getParameterUrl().id_token){
    sessionStorage.setItem('x-token', volt.getParameterUrl().id_token);
    sessionStorage.setItem('x-entity', volt.getParameterUrl().entity);
    volt.getEnv().then((env)=>{
        window.location.href = env.paths.front;
    });

    // createApp(App, {entity_connect :  volt.getParameterUrl().entity, id_token : volt.getParameterUrl().id_token}).mount('#app')
}else if(volt.getParameterUrl().token_jwk){
    console.log(volt.getParameterUrl().token_jwk);
    createApp(App,{token_new_user : volt.getParameterUrl().token_jwk}).mount('#app')
}else if(volt.getParameterUrl().token){
    console.log(volt.getParameterUrl().token);
    createApp(App,{token : volt.getParameterUrl().token}).mount('#app')
}else if(volt.getParameterUrl().recover){
    console.log(volt.getParameterUrl().recover);
    createApp(App,{recover : volt.getParameterUrl().recover}).mount('#app')
}else if(volt.getParameterUrl().recover_mdp){
    createApp(App,{recover_mdp : volt.getParameterUrl().recover_mdp}).mount('#app')
} else{
    createApp(App).mount('#app')
}

