<template>
  <div class="block-multi-kpi-subtitle" :style="style.title">{{title}}</div>
  <div class="block-multi-kpis">
    <div v-for="kpi in kpis" :key="kpi" >
      <span style="display: none">{{nb=0}}</span>
      <div v-if="(kpi.evolution && kpi.evolution !== '') || (kpi.vs && kpi.vs.unit !== '') ">
        <div v-for="kp in kpi.value" :key="kp" class="block-multi-element-evo">
          <div class="multi-element-title-evo-div">
            <div v-if="kpi.link.name === ''" class="multi-element-title-evo">{{Object.keys(kpi.value)[nb] }}</div>
            <div v-else :style="kpi.style.element" class="multi-element-title-link-evo" :data-name="kpi.link.name" :data-link="kpi.link.value" :data-size="kpi.link.size" :data-type="kpi.link.type" @click="callLinkSec">{{Object.keys(kpi.value)[nb] }}</div>
          </div>
          <div v-if="kpi.vs && kpi.vs.unit !== ''" class="multi-element-value-evo-div">
            <div v-if="kpi.link.name === ''" class="multi-element-alone-evo" :style="kpi.style.value" :data-name="kpi.link.name" :data-link="kpi.link.value" :data-size="kpi.link.size" :data-type="kpi.link.type">{{ number_format(kp) }}</div>
            <div v-else class="multi-element-link-evo" :style="kpi.style.value" :data-name="kpi.link.name" :data-link="kpi.link.value" :data-size="kpi.link.size" :data-type="kpi.link.type" @click="callLinkSec">{{ number_format(kp) }}</div>
            <div v-if="Number(kpi.vs.value) >= 0" class="multi-element-evolution-green">{{ number_format(kpi.vs.value) }} %</div>
            <div v-else class="multi-element-evolution-red"> {{ number_format(kpi.vs.value) }} %</div>
            <div class="multi-element-unit">{{ kpi.vs.unit }}</div>
          </div>
          <div v-else class="multi-element-value-evo-div">
            <div v-if="kpi.link.name === ''" class="multi-element-alone-evo" :style="kpi.style.value" :data-name="kpi.link.name" :data-link="kpi.link.value" :data-size="kpi.link.size" :data-type="kpi.link.type">{{ number_format(kp) }}</div>
            <div v-else class="multi-element-link-evo" :style="kpi.style.value" :data-name="kpi.link.name" :data-link="kpi.link.value" :data-size="kpi.link.size" :data-type="kpi.link.type" @click="callLinkSec">{{ number_format(kp) }}</div>
            <div v-if="Number(kpi.evolution) >= 0" class="multi-element-evolution-green">+{{ number_format(kpi.evolution) }} %</div>
            <div v-else class="multi-element-evolution-red"> {{ number_format(kpi.evolution) }} %</div>
            <div class="multi-element-versus">vs {{ parseInt(year) - 1 }}</div>
          </div>
          <span style="display: none">{{nb++}}</span>
        </div>
      </div>
      <div v-else>
        <div v-for="kp in kpi.value" :key="kp" class="block-multi-element">
          <div v-if="kpi.link.name === ''" class="multi-element-title">{{Object.keys(kpi.value)[nb] }}</div>
          <div v-else :style="kpi.style.element" class="multi-element-title-link" :data-name="kpi.link.name" :data-link="kpi.link.value" :data-size="kpi.link.size" :data-type="kpi.link.type" @click="callLinkSec">{{Object.keys(kpi.value)[nb] }}</div>
          <div v-if="kpi.link.name === ''" class="multi-element-alone" :style="kpi.style.value" :data-name="kpi.link.name" :data-link="kpi.link.value" :data-size="kpi.link.size" :data-type="kpi.link.type">{{ number_format(kp) }}</div>
          <div v-else class="multi-element-link" :style="kpi.style.value" :data-name="kpi.link.name" :data-link="kpi.link.value" :data-size="kpi.link.size" :data-type="kpi.link.type" @click="callLinkSec">{{ number_format(kp) }}</div>
          <div class="multi-element-label">Annonces</div>
          <span style="display: none">{{nb++}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import volt from "@/volt";

export default {
  name: "MultiBlock",
  emits: ["callLinkPri", "callLinkSec"],
  props:{
    limit : Number,
    title : String,
    kpis : [Array],
    value : [String, Number],
    style : Object,
    link : Object,
    size : String,
    year : String
  },
  data(){
    return{
      backcolor : ""
    }
  },
  mounted() {
  },
  methods:{
    number_format(number){
      return volt.number_format(number);
    },
    callLinkPri(){
      volt.list =  {
        name : this.link.name,
        value : this.link.value,
        size : this.link.size,
        type : this.link.type,
        limit : this.limit,
        offset : 0,
        order : 1,
        by : ''
      };
      this.$emit('callLinkPri');
    },
    callLinkSec(e){
      volt.list =  {
        name : e.target.getAttribute('data-name'),
        value : e.target.getAttribute('data-value'),
        size : e.target.getAttribute('data-size'),
        type : e.target.getAttribute('data-type'),
        limit : this.limit,
        offset : 0,
        order : 1,
        by : ''
      };
      this.$emit('callLinkSec');
    }
  }
}
</script>

<style scoped>
.block-multi-kpi-subtitle{
  text-align: start;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  height: 25px;
  display: flex;
  flex-direction: row;
  /*border: solid 1px #f3751b;*/
}
.block-multi-kpis{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.block-multi-element{
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 2px 10px 10px 10px;
  height: 130px;
  width: 110px;
}
.multi-element-link{
  cursor: pointer;
  color : #d89d06;
  font-size: 30px;
}
.multi-element-alone{
  font-size: 30px;
}
.multi-element-title{
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 70px;
  padding-top: 15px;
  /*align-items: center;*/
  text-align: start;
}
.multi-element-title-link{
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 70px;
  padding-top: 15px;
  /*align-items: center;*/
  text-align: start;
  cursor: pointer;
}
.multi-element-label{
  font-size: 10px;
}

.block-multi-element-evo{
  display: flex;
  flex-direction: row;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 2px 10px 10px 10px;
  height: 120px;
  width: 250px;
}
.multi-element-link-evo{
  cursor: pointer;
  color : #d89d06;
  font-size: 30px;
  margin-bottom: 5px;
}
.multi-element-alone-evo{
  font-size: 30px;
  margin-bottom: 5px;
}
.multi-element-title-evo{
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 70px;
  /*align-items: center;*/
  text-align: start;
}
.multi-element-title-link-evo{
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 70px;
  /*align-items: center;*/
  text-align: start;
  cursor: pointer;
}
.multi-element-label-evo{
  font-size: 10px;
}
.multi-element-title-evo-div{
  display: flex;
  flex-direction: column;
  padding: 15px;
  justify-content: start;
  width: 50%;
  height: 80%;
  /*cursor: pointer;*/
  /*border: solid 1px #f3751b;*/
}
.multi-element-value-evo-div{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 15px;
  width: 50%;
  /*cursor: pointer;*/
  /*border: solid 1px #f3751b;*/
}
.multi-element-evolution-green{
  color: green;
}
.multi-element-evolution-red{
  color: red;
}
.multi-element-versus{

}
.multi-element-unit{
  font-size: 11px;
}
@media (max-width: 1500px) {
  .block-multi-element{
    padding: 2px 10px 10px 10px;
    height: 120px;
    width: 70px;
    font-size: 10px !important;
  }
  .block-multi-element-evo{
    height: 120px;
    width: 150px;
    font-size: 10px !important;
  }
  .multi-element-link{
    font-size: 16px;
  }
  .multi-element-alone{
    font-size: 16px;
  }
  .multi-element-link-evo{
    font-size: 18px;
  }
  .multi-element-alone-evo{
    font-size: 18px;
  }
  .multi-element-title-link{
    height: 80px;
    overflow: hidden;
    padding-top: 5px;
  }
  .multi-element-title{
    height: 80px;
    overflow: hidden;
    padding-top: 5px;
  }
  .multi-element-versus{
    font-size: 18px;
  }
  .multi-element-unit{
    font-size: 10px;
  }
  .multi-element-evolution-red{
    font-size: 15px;
  }
  .multi-element-evolution-green{
    font-size: 15px;
  }
}
@media (max-width: 1250px) {
  .block-multi-element{
    padding: 2px 10px 10px 10px;
    height: 90px;
    width: 50px;
    font-size: 10px !important;
  }
  .block-multi-element-evo{
    height: 90px;
    width: 150px;
    margin: 3px;
    font-size: 10px !important;
  }
  .multi-element-link{
    font-size: 12px;
  }
  .multi-element-alone{
    font-size: 12px;
  }
  .multi-element-link-evo{
    font-size: 15px;
  }
  .multi-element-alone-evo{
    font-size: 15px;
  }
  .multi-element-title-link{
    height: 80px;
    overflow: hidden;
    padding-top: 5px;
  }
  .multi-element-title{
    height: 80px;
    overflow: hidden;
    padding-top: 5px;
  }
  @media (max-width: 800px) {
    .block-multi-element{
      margin-bottom: 5px;
      width: 250px;
    }
    .block-multi-element-evo{
     margin-bottom: 5px;
      width: 250px;
    }
  }
}
</style>