/* eslint-disable */
const volt = require("@/volt");

module.exports.getUsersEntity = async (id, entity, year) => {
  return await volt.get("users/entity/find?entity_id="+id+"&year="+year, entity);
}
module.exports.getUsers = async (entities, year) => {
    return await volt.get("users/findAll?year="+year, entities);
}
module.exports.getUserDetails = async (id, entity, year) => {
    return await volt.get("users/findAll?user="+id+"&year="+year, entity);
}