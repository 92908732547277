<template>
  <div class="kpi-subtitle" :style="style.title">{{title}}</div>
  <div class="simple-block">
    <div class="block-multi-element">
      <div class="multi-element-title-evo-div">
        <div class="multi-element-title-evo">{{label}}</div>
      </div>
      <div v-if="link.name === ''"  class="kpi-alone" :style="style.value" >  <div class="kpi-value">{{ number_format(value) }}</div></div>
      <div v-else class="kpi-alone-link" :style="style.value" :data-type="link.type" >  <div class="kpi-value">{{ number_format(value) }}</div></div>
      <div v-if="evolution && evolution !== ''">
        <div class="evo-div">
          <div v-if="Number(evolution) >= 0" class="element-evolution-green">+{{ number_format(evolution) }} %</div>
          <div v-else class="element-evolution-red"> {{ number_format(evolution) }} %</div>
          <div class="multi-element-versus">vs {{ parseInt(year) - 1 }}</div>
        </div>
      </div>
    </div>
    <div class="block-multi-graph">
      <canvas id="bar-bar1" class="bar-graph-hori"></canvas>
    </div>
  </div>
</template>

<script>
import volt from "@/volt";
import {multi_bar_horizontal} from "@/charts_obs";

export default {
  name: "BlockGraph",
  emits: ["callLinkPri", "callLinkSec"],
  props:{
    limit : Number,
    title : String,
    kpis : [Array],
    style : Object,
    link : Object,
    size : String,
    year : String,
    evolution : String
  },
  mounted() {
    let data_values = [];
    let data_links = [];
    this.kpis.forEach((data)=>{
      data_values.push(data.value);
      data_links.push(data.link.name);
    })
    if(data_values[0]){
      this.value = Number(data_values[0]['value']).toFixed(0);
      this.label = data_values[0]['label'];
      multi_bar_horizontal('bar1',[[data_values[1],data_values[2],data_values[3]], [data_values[4],data_values[5],data_values[6]]]);
    }
    // multi_bar_horizontal('bar2',[data_values[4],data_values[5],data_values[6],data_values[7]]);
  },
  data(){
    return{
      backcolor : "",
      value : "",
      label : ""
    }
  },
  methods:{
    number_format(number){
      return volt.number_format(number);
    },
    callLinkPri(){
      volt.list =  {
        name : this.link.name,
        value : this.link.value,
        size : this.link.size,
        type : this.link.type,
        limit : this.limit,
        offset : 0,
        order : 1,
        by : ''
      };
      this.$emit('callLinkPri');
    },
    callLinkSec(e){
      volt.list =  {
        name : e.target.getAttribute('data-name'),
        value : e.target.getAttribute('data-value'),
        size : e.target.getAttribute('data-size'),
        type : e.target.getAttribute('data-type'),
        limit : this.limit,
        offset : 0,
        order : 1,
        by : ''
      };
      this.$emit('callLinkSec');
    }
  }
}
</script>

<style scoped>
.simple-block{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.kpi-subtitle{
  text-align: start;
  font-size: 14px;
  font-weight: bold;
  /*margin: 2px;*/
  padding: 15px;
  /*font-weight: v-bind(weigthTitle);*/
  display: flex;
  flex-direction: row;
  /*height: 20%;*/
  /*border: solid 1px #f3751b;*/
}
.kpi-value{
  /*border: solid 1px #f3751b;*/
  /*margin-top: 20px;*/
  /*font-weight: bold;*/
  font-size: 30px;
}
.evo-div{
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 50px;
}
.element-evolution-green{
  color: green;
}
.element-evolution-red{
  color: red;
}
.multi-element-title-evo{
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 20px;
  /*align-items: center;*/
  text-align: start;
}
.multi-element-title-evo-div{
  display: flex;
  flex-direction: column;
  padding: 15px 0 0 0;
  justify-content: start;
  width: 50%;
  height: 80%;
  /*cursor: pointer;*/
  /*border: solid 1px #f3751b;*/
}
.multi-element-value-evo-div{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 15px;
  width: 50%;
  /*cursor: pointer;*/
  /*border: solid 1px #f3751b;*/
}
.block-multi-element{
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 2px 10px 10px 10px;
  height: 130px;
  width: 15%;
}
.block-multi-graph{
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 2px 10px 10px 10px;
  height: 130px;
  width: 70%;
}
/*.bar-graph-hori{*/
/*  height: 60px;*/
/*}*/
@media (max-width: 1250px) {
  .kpi-subtitle{
    text-align: start;
    font-size: 12px;
    font-weight: bold;
    padding: 5px;
    display: flex;
    flex-direction: row;
    height: 45%;
  }
  .kpi-value{
    margin-top: 5px;
    font-size: 15px;
  }
}
@media (max-width: 800px) {
  .kpi-subtitle{
    height: 10%;
  }
}
</style>
