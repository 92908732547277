<template>
  <div class="kpi-list">

    <div class="visuel-brick visuel-block">

    </div>



  </div>
</template>

<script>
// import volt from "../volt";
// import MultiBlock from "@/elements/MultiBlock";
// import JaugeLine from "@/elements/JaugeLine";
// import ClusterBlock from "@/elements/ClusterBlock";
// import SimpleBlock from "@/elements/SimpleBlock";
// import MapsVue from "@/components/MapsVue";
// import HistoBlock from "@/elements/HistoBlock";
// import LineBlock from "@/elements/LineBlock";
// import JaugeRadius from "@/elements/JaugeRadius";
// import BlockGraph from "@/elements/BlockGraph";
export default {
  name: "KpiListAdmin",
  components : {
    // BlockGraph, JaugeRadius, LineBlock, HistoBlock, MapsVue, SimpleBlock, ClusterBlock, JaugeLine, MultiBlock
  },
  props:{
  },
  mounted() {
  },
  watch:{
  },
  data(){
    return{
      jauge_elem : [],
      weigthTitle : 0,
      margBotEle : '2px',
      backcolor : "",
      maps : {},
      title_ : "",
      irisMap : {},
      arrMap : {}
    }
  },
  methods:{
  }
}
</script>

<style scoped>
.kpi-list{
  padding: 5px;
  display: grid;
  /*grid-column-start: v-bind(style['gridCS']);*/
  /*grid-column-end: v-bind(style['gridCE']);*/
  margin-bottom: v-bind(margBotEle);
}
.visuel-brick{
  border: solid 1px #7a7a7a;
  border-radius: 10px;
  height: 200px;
  overflow: auto;
}
.visuel-block{
  /*cursor: pointer;*/
}
.visuel-map{
  height:400px;
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-template-rows: repeat(20, 1fr);
}
.visuel-block-graph{

}
.visuel-graph-line{

}
.visuel-block-multi{

}
.visuel-jauge{

}
.visuel-graph-doughnut{

}
.kpi-subtitle{
  text-align: start;
  font-size: 14px;
  font-weight: bold;
  padding: 15px;
  display: flex;
  flex-direction: row;
  height: 30%;
}
.kpi-subtitle-map{
  text-align: start;
  font-size: 14px;
  font-weight: bold;
  padding: 15px;
  grid-column-start: 1;
  grid-column-end: 21;
  grid-row-start: 1;
  grid-row-end: 2;
}
@media (max-width: 1250px) {
  .visuel-brick{
    height: 150px;
    padding-bottom: 10px;
  }
  .visuel-block-multi{
    height: max-content;
    padding-bottom: 10px;
  }
  .visuel-map{
    height: 300px;
  }
  .visuel-graph-histograme{
    height: max-content;
  }
  .visuel-graph-line{
    height: max-content;
  }
}
@media (max-width: 800px) {
  .kpi-list{
    padding: 5px;
    display: grid;
    grid-column-start: 1;
    grid-column-end: 13;
    margin-bottom: v-bind(margBotEle);
  }
  .visuel-brick{
    height: max-content;
    padding-bottom: 10px;
  }
  .visuel-map{
    height: 300px;
  }
  .visuel-block-multi{
    height: max-content;
  }
  .visuel-geauge{
    height: max-content;
  }
  .visuel-graph-histograme{
    height: max-content;
  }
}
</style>