<template>
  <div class="menu-float" :id="'menu-float-'+title">
    <div class="menu-float-top-bar" :id="'top-bar-menu-float-'+title" :data-id="'menu-float-'+title" @mousedown="move" @mouseup="stopMove" >
      {{title}}
    </div>
<!--    <div class="closed-menu-float" :data-id="'menu-float-'+title" @click="closeFloatMenu"><i class="fas fa-times-circle" :data-id="'menu-float-'+title" @click="closeFloatMenu" :style="'color:'+color"></i></div>-->
<!--    <div class="closed-menu-float" :data-id="title" @click="reduceFloatMenu"><i class="fas fa-minus" :data-id="title" :id="'icon-reduce-menu-float-content-'+title" :style="'color:'+color"></i></div>-->
    <div class="menu-float-content" :id="'menu-float-content-'+title">
      <div class="detail-content-floatmenu" :id="'detail-content-' + title">
        <div class="outils-contant-float" :id="'outils-content-'+ title">
          <div class="closed-menu-float" :data-id="'menu-float-'+title" @click="closeFloatMenu"><i class="fas fa-times-circle" :data-id="'menu-float-'+title" @click="closeFloatMenu" :style="'color:'+color"></i></div>
<!--          <div class="title-menu-params">Entêtes</div>-->
          <button class="valid-list btn-help-rapport"><i class="fa-solid fa-circle-info"></i>Guide</button>
          <div class="help-rapport-create">
            <p>
              Pour personnaliser le rapport, vous pouvez:
            </p>
            <ul>
              <li>Ajouter / modifier le titre de votre rapport (en cliquant sur le champ ‘Titre du rapport’)</li>
              <li>Ajouter / supprimer des colonnes ou réinitialiser complétement le rapport (en supprimant l’ensemble des colonnes)</li>
              <li>Sélectionner les données à afficher dans chaque colonne (en cliquant sur le champ ‘Donnée’ qui ouvrira la liste déroulante avec l’ensemble des données disponibles)</li>
              <li>Définir la largeur de chaque colonne (en cliquant sur le champ ‘Largeur’). La largeur maximale pour l’ensemble des colonnes ne doit pas dépasser 20 unités.</li>
            </ul>
          </div>
          <div v-if="Object.keys(list).length === 0" class="loader-entete-position"><LoaderParams type="entete"/></div>
          <div v-else>
            <div class="label-rapport-title">
              <label class="label-input-rapport-title">Titre du rapport </label>
<!--              <input id="rapport-title" :value="list.title"/>-->
              <input id="rapport-title" :value="new_title" @keyup="changeTitle"/>
              <div v-if="error_msg !== ''" class="error-msg-list-creat">{{error_msg}}</div>
            </div>
            <div class="div-entete-position">
              <span style="display: none">{{c=0}}</span>
              <div v-for="position in listEnc.headers" :key="position" class="entete-select-list" :id="'entete-select-list_'+c" draggable="true" @dragstart="dragstart_handler" >
<!--                <div class="colonne-number">Colonne {{c+1}}<i class="fas fa-pen-alt" :data-index="c" @click="editEntete"></i><i class="fas fa-trash-alt" :data-index="c" @click="deleteEntete"></i></div>-->
                <div class="colonne-number">Colonne {{c+1}}<i class="fas fa-trash-alt" :data-index="c" @click="deleteEntete"></i></div>
                <div class="label-input-div"><label class="label-input-form">Donnée </label><input class="entete-input-name" :value="position.name" :data-index="c" @click="editEntete" /></div>
                <div class="label-input-div">
                  <label class="label-input-form-size">Largeur </label>
  <!--                <div class="entete-input-size">-->
  <!--                  <img v-if="position.style['grid-column-end'] !== undefined" class="col-png-select col1" :src="'col_'+(position.style['grid-column-end'] - position.style['grid-column-start'])+'.svg'" :alt="(position.style['grid-column-end'] - position.style['grid-column-start']) + ' colonne(s)'"/>-->
  <!--                  <div v-else class="col-png-select col1"></div>-->
  <!--                </div>-->
  <!--                <input type="number" class="entete-input-size" @change="definegridCol" :value="position.style['grid-column-end'] - position.style['grid-column-start']" :data-index="c" @click="callSize"/>-->
                  <div class="entete-input-size" :data-index="c" :id="'entete-input-size-'+c" @click="callSize" :data-value="position.style['grid-column-end'] - position.style['grid-column-start']">{{(position.style['grid-column-end'] - position.style['grid-column-start'])}}</div>
  <!--                <div type="number" class="entete-input-size" @change="definegridCol" :data-index="c" @click="callSize" data-value="0">{{position.style['grid-column-end']}}</div>-->
                </div>

                <!--              <div class="drop-target" @dragover="dragover_handler" @drop="drop_handler">ici</div>-->
                <span style="display: none">{{c++}}</span>
            </div>
          </div>
<!--            <div class="entete-select-list-add" @click="addEntete"><i class="fas fa-plus-square"></i></div>-->
          </div>
          <div class="div-valid-list">
<!--            <button class="valid-list" @click="chargeList">Charger la liste</button>-->
            <div class="div-btn-list">
              <div  class="valid-list" @click="addEntete"><i class="far fa-plus"> </i> Ajouter une colonne</div>
              <div  class="valid-list" @click="resetEntete"><i class="fa-solid fa-arrows-rotate"> </i> Réinitialiser</div>
            </div>
            <div class="div-btn-list-2">
              <div v-if="type === 'user' || type === '' || type === undefined" class="valid-list" id="input-rapport-type" @click="changeType">Partager le rapport<i style="font-size: 23px;" class="far fa-square"></i></div>
              <div v-else class="valid-list" id="input-rapport-type" @click="changeType">Rapport partagé<i style="font-size: 23px;" class="far fa-check-square"></i></div>
              <button class="valid-list btn-save-rapport" @click="chargeList">Enregistrer le rapport</button>
            </div>
          </div>
          <div class="pop-col-select" id="pop-col-select">
            <div id="pop-sub-col-select">
              <div class="div-col-select" @click="callSizeSelect" data-value="1"><div class="div-nbr-select">1</div><img class="col-png-select col1" src="col_1.svg" data-value="1"/></div>
              <div class="div-col-select" @click="callSizeSelect" data-value="2"><div class="div-nbr-select">2</div><img class="col-png-select col2" src="col_2.svg" data-value="2"/></div>
              <div class="div-col-select" @click="callSizeSelect" data-value="3"><div class="div-nbr-select">3</div><img class="col-png-select col3" src="col_3.svg" data-value="3"/></div>
              <div class="div-col-select" @click="callSizeSelect" data-value="4"><div class="div-nbr-select">4</div><img class="col-png-select col4" src="col_4.svg" data-value="4"/></div>
              <div class="div-col-select" @click="callSizeSelect" data-value="5"><div class="div-nbr-select">5</div><img class="col-png-select col5" src="col_5.svg" data-value="5"/></div>
            </div>
          </div>
          <div class="pop-field-choice" id="pop-field-choice">
            <div class="title-menu-params">Valeurs</div>
<!--          <button @click="console.log(Object.keys(entetes))">entetes</button>-->
<!--          <div class="div-btn-choice-entete">-->
<!--&lt;!&ndash;          <div class="entete-pri">Global</div>&ndash;&gt;-->
<!--            <div class="div-btn-choice-entete" v-for="ent in Object.keys(entetes)" :key="ent">-->
<!--              <div v-if="typeof entetes[ent] === 'string'" class="entete-choice">{{entetes[ent]}}</div>-->
<!--              <div v-else class="div-btn-choice-entete">-->
<!--  &lt;!&ndash;              <div class="entete-pri">{{ent}}</div>&ndash;&gt;-->
<!--                <div v-for="enteteSub1 in Object.keys(entetes[ent])" :key="enteteSub1">-->
<!--                  <div v-if="typeof entetes[ent][enteteSub1] === 'string'" class="entete-choice">{{entetes[ent][enteteSub1]}}</div>-->
<!--                  <div v-else class="div-btn-choice-entete">-->
<!--  &lt;!&ndash;                  <div class="entete-pri">{{enteteSub1}}</div>&ndash;&gt;-->
<!--                    <div v-for="enteteSub2 in Object.keys(entetes[ent][enteteSub1])" :key="enteteSub2">-->
<!--                      <div v-if="typeof entetes[ent][enteteSub1][enteteSub2] === 'string'" class="entete-choice">{{entetes[ent][enteteSub1][enteteSub2]}}</div>-->
<!--                      <div v-else class="div-btn-choice-entete">-->
<!--  &lt;!&ndash;                      <div class="entete-pri">{{enteteSub2}}</div>&ndash;&gt;-->
<!--                        <div v-for="enteteSub3 in Object.keys(entetes[ent][enteteSub1][enteteSub2])" :key="enteteSub3">-->
<!--                          <div v-if="typeof entetes[ent][enteteSub1][enteteSub2][enteteSub3] === 'string'" class="entete-choice">{{entetes[ent][enteteSub1][enteteSub2][enteteSub3]}}</div>-->
<!--                          <div v-else class="div-btn-choice-entete">-->
<!--  &lt;!&ndash;                          <div class="entete-pri">{{enteteSub3}}</div>&ndash;&gt;-->
<!--                            <div v-for="enteteSub4 in Object.keys(entetes[ent][enteteSub1][enteteSub2][enteteSub3])" :key="enteteSub4">-->
<!--                              <div v-if="typeof entetes[ent][enteteSub1][enteteSub2][enteteSub3][enteteSub4] === 'string'" class="entete-choice">{{entetes[ent][enteteSub1][enteteSub2][enteteSub3][enteteSub4]}}</div>-->
<!--                              <div v-else class="div-btn-choice-entete">-->
<!--                                <div class="entete-pri">{{enteteSub4}}</div>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--            <div id="div-btn-choices-field">-->
              <div v-if="positionSelect !== '' && entetes !== undefined && entetes.feature !== undefined" id="div-btn-choices-field">
    <!--            <div class="div-btn-choice-entete" v-for="ent in Object.keys(entetes)" :key="ent">-->
    <!--              <div v-if="typeof entetes[ent] === 'string'" class="entete-choice">{{entetes[ent]}}</div>-->
    <!--            </div>-->
                <div class="div-btn-choice-entete" v-for="ent in Object.keys(entetes.feature.properties)" :key="ent">
                  <div v-if="typeof entetes.feature.properties[ent] === 'string' && glossaire[entetes.feature.properties[ent]] && glossaire[entetes.feature.properties[ent]].display" class="entete-choice-bnt entete-choice-properties" :data-value="'feature.properties.'+entetes.feature.properties[ent]" @click="selectEnteteProp">{{glossaire[entetes.feature.properties[ent]].value}}</div>
                </div>
                <div class="div-btn-choice-entete" v-for="ent in Object.keys(entetes.updates)" :key="ent">
                  <div v-if="typeof entetes.updates[ent] === 'string' && glossaire[entetes.feature.properties[ent]] && glossaire[entetes.feature.properties[ent]].display" class="entete-choice-bnt entete-choice-updates" :data-value="'updates.'+entetes.updates[ent]" @click="selectEnteteUp">{{glossaire[entetes.updates[ent]].value}}</div>
                </div>
              </div>
<!--            </div>-->


<!--          <div>-->
<!--            <div>liste des models utilisateurs</div>-->
<!--            <div>liste des entêtes</div>-->
<!--            <div>liste des entêtes choisies</div>-->
<!--            <div>liste des valeurs en fonction de l'entête, de l'année et de la commune</div>-->
<!--            <div>liste des valeurs choisies</div>-->
<!--            <div>Enregistrer un nouveau model</div>-->
<!--          </div>-->
<!--          <MapsVue-->
<!--               title = 'pop'-->
<!--               gridCS = "1"-->
<!--               gridCE = "9"-->
<!--               gridRS = "1"-->
<!--               gridRE = "12"-->
<!--          />-->
<!--          <div class="details-table-annonce">-->
<!--            <table>-->

<!--            </table>-->
<!--          </div>-->
<!--          <ListAnnonces-->
<!--              title = 'pop'-->
<!--              gridCS = "1"-->
<!--              gridCE = "21"-->
<!--              gridRS = "12"-->
<!--              gridRE = "21"-->
<!--              :list = list-->
<!--              type = 'popup'-->
<!--              :limit = "limit"-->
<!--              @callList = "callList"-->
<!--              @callAnnonce = "callAnnonce"-->
<!--          />-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import volt from '../volt.js';
import LoaderParams from "@/components/LoaderParams";
// import list from "@/services/listes";
// import MapsVue from "@/components/MapsVue";
// // import olMap from "@/olMap";
// import ListAnnonces from "@/elements/ListAnnonces";

export default {
  name: "PopMenuChart",
  components: {LoaderParams},
  emits: ["callList", "callAnnonce"],
  props:{
    title : String,
    color : String,
    annonce: Object,
    list : Object,
    limit : Number,
    models : Object,
    entetes : Object,
    values : Object,
    year : String,
    entity : String,
    glossaire : Object,
    list_id : [String, Number],
    user : Object,
    list_user : Object,
    list_entity : Object,
  },
  watch:{
    annonce(){
    },
    entetes(){
    },
    enteteSelect(){
    },
    list(){
      this.listEnc =  volt.o(this.list);
      this.type = this.list.type;
    },
    list_id(){
      this.list_id_data = this.list_id;
      console.log(this.list_id_data);
    }
  },
  data(){
    return{
      error_msg : '',
      list_id_data : this.list_id,
      type : 'user',
      enteteSelect : '',
      positionSelect : '',
      new_title : '',
      listPers : {
        title : "",
        headers : [
          {
            name: "",
            field: "",
            filter: false,
            search: true,
            style: {
              "grid-column-start": 1,
              "grid-column-end": 3
            }
          },
          {
            name: "",
            field: "",
            filter: false,
            search: true,
            style: {
              "grid-column-start": 3,
              "grid-column-end": 5
            }
          },
          {
            name: "",
            field: "",
            filter: false,
            search: true,
            style: {
              "grid-column-start": 5,
              "grid-column-end": 7
            }
          },
          {
            name: "",
            field: "",
            filter: false,
            search: true,
            style: {
              "grid-column-start": 7,
              "grid-column-end": 9
            }
          },
          {
            name: "",
            field: "",
            filter: false,
            search: true,
            style: {
              "grid-column-start": 9,
              "grid-column-end": 11
            }
          },
          {
            name: "",
            field: "",
            filter: false,
            search: true,
            style: {
              "grid-column-start": 11,
              "grid-column-end": 13
            }
          },
          {
            name: "",
            field: "",
            filter: false,
            search: true,
            style: {
              "grid-column-start": 13,
              "grid-column-end": 15
            }
          },
          {
            name: "",
            field: "",
            filter: false,
            search: true,
            style: {
              "grid-column-start": 15,
              "grid-column-end": 17
            }
          },
          {
            name: "",
            field: "",
            filter: false,
            search: true,
            style: {
              "grid-column-start": 17,
              "grid-column-end": 19
            }
          },
          {
            name: "",
            field: "",
            filter: false,
            search: true,
            style: {
              "grid-column-start": 19,
              "grid-column-end": 21
            }
          }
        ],
      },
      listEnc : volt.o(this.list)
    }
  },
  mounted() {
    console.log(this.list_id);
    this.type = this.list.type;
    this.new_title = this.list.title;
  },
  methods:{
    changeTitle(e){
      this.new_title = e.target.value;
    },
    move,
    stopMove,
    closeFloatMenu,
    reduceFloatMenu,
    epingleFloatMenu,
    dragstart_handler(ev) {
      // Add the target element's id to the data transfer object
      ev.dataTransfer.setData("application/my-app", ev.target.id);
      ev.dataTransfer.effectAllowed = "move";
    },
    dragover_handler(ev) {
      ev.preventDefault();
      ev.dataTransfer.dropEffect = "move";
    },
    drop_handler(ev) {
      ev.preventDefault();
      // Get the id of the target and add the moved element to the target's DOM
      const data = ev.dataTransfer.getData("application/my-app");
      ev.target.appendChild(document.getElementById(data));
    },
    callList(){
      setTimeout(()=>{ this.$emit('callList');},200);
    },
    callAnnonce(){
      setTimeout(()=>{ this.$emit('callAnnonce');},200);
    },
    selectEnteteProp(e){
      volt.taks('.entete-choice-bnt','',false).forEach((btn)=>{
        btn.style.backgroundColor= "";
        btn.style.color= "";
      })
      e.target.style.backgroundColor= "#f3b61b";
      e.target.style.color= "#232222";
      this.enteteSelect = e.target.getAttribute('data-value');
      this.listEnc.headers[this.positionSelect].field = this.enteteSelect.split('.')[2];
      this.listEnc.headers[this.positionSelect].preField = '';
      this.listEnc.headers[this.positionSelect].filter = true;
      this.listEnc.headers[this.positionSelect].search = true;
      this.listEnc.headers[this.positionSelect].name = this.glossaire[this.enteteSelect.split('.')[2]].value;
      this.positionSelect = '';
      volt.taks('.fa-pen-alt','',false).forEach((btn)=>{
        btn.style.backgroundColor= "";
      })
      volt.tak('pop-field-choice').style.height = '';
      volt.tak('pop-field-choice').style.left = '';
      volt.tak('pop-field-choice').style.top = '';
    },
    selectEnteteUp(e){
      volt.taks('.entete-choice-bnt','',false).forEach((btn)=>{
        btn.style.backgroundColor= "";
        btn.style.color= "";
      })
      e.target.style.backgroundColor= "#f3b61b";
      e.target.style.color= "#232222";
      this.enteteSelect = e.target.getAttribute('data-value');
      this.listEnc.headers[this.positionSelect].field = this.enteteSelect.split('.')[1];
      this.listEnc.headers[this.positionSelect].preField = this.enteteSelect.split('.')[0];
      this.listEnc.headers[this.positionSelect].filter = true;
      this.listEnc.headers[this.positionSelect].search = true;
      this.listEnc.headers[this.positionSelect].name = this.glossaire[this.enteteSelect.split('.')[1]].value;
      this.positionSelect = '';
      volt.taks('.fa-pen-alt','',false).forEach((btn)=>{
        btn.style.backgroundColor= "";
      })
      volt.tak('pop-field-choice').style.height = '';
      volt.tak('pop-field-choice').style.left = '';
      volt.tak('pop-field-choice').style.top = '';
    },
    addEntete(){
      this.listEnc.headers.push( {
        name: "N°Enregistrement",
        field: "id_num",
        filter: true,
        search: true,
        style: {
          "grid-column-start": 20,
          "grid-column-end": 21
        }
      })
      this.definegridCol();
    },
    resetEntete(){
      if(confirm('voulez vous réinitialiser le rapport?')){
        this.listEnc.headers = [{
          name: "N°Enregistrement",
          field: "id_num",
          filter: true,
          search: true,
          style: {
            "grid-column-start": 1,
            "grid-column-end": 1
          }
        }];
      }
    },
    changeType(){
      this.error_msg = "";
      console.log(this.list_user);
      console.log(this.list_entity);
      if((this.type === 'user' || this.type === '' || this.type === undefined) && this.list_entity.length < 8){
        this.type = 'entity';
      }else if (this.list_user.length < 8){
        this.type = 'user';
      }else{
        if(this.list_user.length >= 8 && this.list_entity.length >= 8){
          this.error_msg = 'Vous avez atteint le nombre maximal de rapports autorisés';
        }else if(this.list_user.length >= 8){
          this.error_msg = 'Vous avez atteint le nombre maximal de rapports personnels autorisés';
        }else if(this.list_entity.length >= 8){
          this.error_msg = 'Vous avez atteint le nombre maximal de rapports partagés autorisés';
        }
      }
    },
    deleteEntete(e){
      this.error_msg = '';
      this.listEnc.headers.splice(e.target.getAttribute('data-index'), 1);
      setTimeout(()=>{this.definegridCol();},100);
    },
    editEntete(e){
      volt.taks('.entete-input-name', '', false).forEach((input, i) => {
        this.listEnc.headers[i].name = input.value;
      });
      volt.tak('pop-col-select').style.height = '';
      volt.tak('pop-col-select').style.left = '';
      volt.tak('pop-col-select').style.top = '';
      volt.tak('pop-sub-col-select').style.display = '';
      volt.taks('.entete-input-size','',false).forEach((btn)=>{
        btn.style.backgroundColor= "";
      })
      volt.taks('.fa-pen-alt','',false).forEach((btn)=>{
        btn.style.backgroundColor= "";
      })
      volt.taks('.entete-input-name','',false).forEach((btn)=>{
        btn.style.backgroundColor= "";
      })
      if(this.positionSelect === e.target.getAttribute('data-index')){
        this.positionSelect = '';
        volt.tak('pop-field-choice').style.height = '';
        volt.tak('pop-field-choice').style.left = '';
        volt.tak('pop-field-choice').style.top = '';
      }else{
        this.positionSelect = e.target.getAttribute('data-index');
        e.target.style.backgroundColor = "#f3b61b";
        let startPop = e.target.offsetLeft;
        if((volt.tak('pop-field-choice').offsetWidth + e.pageX) > screen.width){
          startPop = e.pageX - volt.tak('pop-field-choice').offsetWidth;
        }
        let startPopTop = e.target.offsetTop + 25;
        if((500 + e.pageY) > screen.height){
          startPopTop = startPopTop - 425;
        }

        let positionPop = startPop + 'px';
        volt.tak('pop-field-choice').style.height = '400px';
        volt.tak('pop-field-choice').style.left = positionPop;
        volt.tak('pop-field-choice').style.top = startPopTop+'px';
        // volt.tak('pop-field-choice').style.top = startPopTop+'px';
      }
    },
    callSize(e){
      this.error_msg = '';
      volt.taks('.entete-input-name', '', false).forEach((input, i) => {
        this.listEnc.headers[i].name = input.value;
      });
      volt.tak('pop-field-choice').style.height = '';
      volt.tak('pop-field-choice').style.left = '';
      volt.tak('pop-field-choice').style.top = '';
      volt.taks('.entete-input-size','',false).forEach((btn)=>{
        btn.style.backgroundColor= "";
      })
      volt.taks('.fa-pen-alt','',false).forEach((btn)=>{
        btn.style.backgroundColor= "";
      })
      volt.taks('.entete-input-name','',false).forEach((btn)=>{
        btn.style.backgroundColor= "";
      })
      if(this.positionSelect === e.target.getAttribute('data-index')){
        this.positionSelect = '';
        volt.tak('pop-col-select').style.height = '';
        volt.tak('pop-col-select').style.left = '';
        volt.tak('pop-col-select').style.top = '';
        volt.tak('pop-sub-col-select').style.display = '';
      }else{
        this.positionSelect = e.target.getAttribute('data-index');
        e.target.style.backgroundColor = "#f3b61b";
        let startPop = e.target.offsetLeft;
        if((volt.tak('pop-col-select').offsetWidth + e.pageX) > screen.width){
          startPop = e.pageX - volt.tak('pop-col-select').offsetWidth;
        }
        let startPopTop = e.target.offsetTop + 30;
        console.log(startPopTop, e.pageY, screen.height)
        if((450 + e.pageY) > screen.height){
          startPopTop = startPopTop - 340;
        }

        let positionPop = (startPop)+'px';
        volt.tak('pop-col-select').style.height = '311px';
        volt.tak('pop-col-select').style.left = positionPop;
        volt.tak('pop-col-select').style.top = startPopTop+'px';
        volt.tak('pop-sub-col-select').style.display = 'block';
      }
    },
    callSizeSelect(e){
      volt.tak('entete-input-size-'+this.positionSelect).setAttribute('data-value', e.target.getAttribute('data-value'));
      this.positionSelect = '';
      volt.tak('pop-col-select').style.height = '';
      volt.tak('pop-col-select').style.left = '';
      volt.tak('pop-col-select').style.top = '';
      volt.tak('pop-sub-col-select').style.display = '';
      this.definegridCol();
    },
    definegridCol(){

      volt.taks('.entete-input-name', '', false).forEach((input, i) => {
        this.listEnc.headers[i].name = input.value;
      });
      volt.taks('.entete-input-size','',false).forEach((input, i)=>{

        if(i === 0){
          this.listEnc.headers[i].style['grid-column-start'] = 1;
        }
        if(parseInt(this.listEnc.headers[i].style['grid-column-start']) + parseInt(input.getAttribute('data-value')) > 21){
          this.listEnc.headers[i].style['grid-column-end'] = 21;
        }else{
          this.listEnc.headers[i].style['grid-column-end'] = parseInt(this.listEnc.headers[i].style['grid-column-start']) + parseInt(input.getAttribute('data-value'));
        }
        if(this.listEnc.headers[(i+1)] !== undefined){
          this.listEnc.headers[(i+1)].style['grid-column-start'] = this.listEnc.headers[i].style['grid-column-end']
        }
      })
      setTimeout(()=> {
        let totalCol = 0;
        volt.taks('.entete-input-size', '', false).forEach((input) => {
          totalCol = totalCol + parseInt(input.getAttribute('data-value'));
        });
        if (totalCol > 20) {
          volt.taks('.entete-input-size', '', false).forEach((input) => {
            input.style.backgroundColor = "#ef7b7b";
          });
        } else {
          volt.taks('.entete-input-size', '', false).forEach((input) => {
            input.style.backgroundColor = "";
          });
        }
      },200);
    },
    chargeList(){
      let error_test = false;
      if(this.type === 'entity' && this.list_entity.length >= 8){
        this.error_msg = 'Vous avez atteint le nombre maximal de rapports partagés autorisés';
        error_test = true;
      }else if ((this.type === '' || this.type === "user" || this.type === undefined)  && this.list_user.length >= 8){
        this.error_msg = 'Vous avez atteint le nombre maximal de rapports personnels autorisés';
        error_test = true;
      }else if(this.list_user.length >= 8 && this.list_entity.length >= 8){
        this.error_msg = 'Vous avez atteint le nombre maximal de rapports autorisés';
        error_test = true;
      }
      volt.taks('.entete-input-name', '', false).forEach((input, i) => {
        this.listEnc.headers[i].name = input.value;
      });
      volt.taks('.entete-input-size', '', false).forEach((input) => {
        if(input.getAttribute('data-value') === "0"){
          input.style.backgroundColor = '#ef7b7b';
          error_test = true;
          this.error_msg = 'Vous ne pouvez pas enregistrer de colonne avec une largeur à 0 ';
        }
      });
      volt.listUser ={
        title : volt.tak('rapport-title').value,
        headers :  volt.o(this.listEnc.headers),
        type : this.type,
        user : this.user["_id"],
      };
      let data = {
        list : volt.listUser,
        // id : this.list_id,
        id : this.list_id_data,
        year : this.year,
        title : volt.tak('rapport-title').value,
        type : this.type
      }
      if(!error_test) {
        if (this.list_id_data === '') {
          volt.post("obs/list/add", data, "", this.entity, this.year).then((response) => {
            volt.listUser._id = response.id;
            this.list_id_data = response.id;
            setTimeout(() => {
              this.$emit('callList', {_id: this.list_id_data});
            }, 200);
            // this.$emit('callList');
          }).catch((reason) => {
            console.log(reason);
            this.$emit('callList');
          })
        } else {
          volt.post("obs/list/update", data, "", this.entity, this.year).then((response) => {
            volt.listUser._id = response.id;
            this.list_id_data = response.id;
            setTimeout(() => {
              this.$emit('callList', {_id: this.list_id_data});
            }, 200);
            // this.$emit('callList');
          }).catch((reason) => {
            console.log(reason);
            this.$emit('callList');
          })
        }
      }


      // setTimeout(()=>{ this.$emit('callList');},500);
      // list.call('user', this.year, 100, 0, this.limit, 1, '', this.entity).then((l)=>{
      //   volt.list = l;
      //   console.log(l);
      //   this.callList();
      // });
    },
    saveList(){

    }
  }
}
var classPinTab = ['pin1','pin2','pin3','pin4','pin5','pin6','pin7'];
var tabValLeft = [ '87px', '389px', '691px', '993px', '1295px', '1597px', '87px'];
var zIndex = 20;
var positionX = 0;
// var positionY = 0;
/* eslint-disable */
function menuMove(e){
  var el = volt.tak(e.target.getAttribute('data-id'));
  if(el !== null){
    if(parseInt(el.style.left) + el.scrollWidth <= window.innerWidth - 5 || el.style.left === ''){
      if(e.clientX - positionX > 2){
        el.style.left =  e.clientX - positionX + 'px';
      }
    }else if(parseInt(el.style.left) + el.scrollWidth >= window.innerWidth - 5){
      el.style.left = parseInt(el.style.left) - 5 + "px";
    }
    if(parseInt(el.style.top) + el.scrollHeight <= window.innerHeight- 5 || el.style.top === ''){
      el.style.top = e.clientY - 15 + 'px';
    }else if(parseInt(el.style.top) + el.scrollHeight >= window.innerHeight - 5){
      el.style.top = parseInt(el.style.top) - 5 + "px";
    }

  }else{
    stopMove(e);
  }
}
function move(e){
  positionX = e.layerX;
  var el = volt.tak(e.target.getAttribute('data-id'));
  volt.ael(document,'mousemove',menuMove);
  e.target.style.height= "30px";
  el.style.opacity = "0.5";
  el.style.boxShadow = "";
  el.style.zIndex = zIndex;
}
function stopMove(e){
  zIndex++;
  var els = [];
  var elBars = [];
  document.removeEventListener('mousemove',menuMove);
  var el = volt.tak(e.target.getAttribute('data-id'));
  var elBar = volt.tak('top-bar-'+e.target.getAttribute('data-id'));
  if(el === null){
    volt.taks('.menu-float-top-bar','',false).forEach((topBarre)=>{
      elBars.push(topBarre);
    })
    volt.taks('.menu-float','',false).forEach((fMenu)=>{
      els.push(fMenu);
    });
  }else{
    els.push(el);
    elBars.push(elBar);
  }
  els.forEach((el, i)=>{
    // e.target.style.height= "";
    elBars[i].style.height= "";
    el.style.opacity = "";
  })
}
function closeFloatMenu(e){
  this.new_title = "Liste personnalisée";
  var el = volt.tak(e.target.getAttribute('data-id'));
  volt.listPop = {};
  el.style.transition="0.7s";
  el.style.transform= "scale(0)";
  this.positionSelect = '';
  volt.taks('.fa-pen-alt','',false).forEach((btn)=>{
    btn.style.backgroundColor= "";
  })
  volt.taks('.entete-input-size','',false).forEach((btn)=>{
    btn.style.backgroundColor= "";
  })
  if(volt.tak('pop-field-choice')){
    volt.tak('pop-field-choice').style.height = '';
    volt.tak('pop-field-choice').style.left = '';
    volt.tak('pop-field-choice').style.top = '';
  }
  if(volt.tak('pop-field-choice')) {
    volt.tak('pop-col-select').style.height = '';
    volt.tak('pop-col-select').style.left = '';
    volt.tak('pop-col-select').style.top = '';
    volt.tak('pop-sub-col-select').style.display = '';
  }
  setTimeout(()=>{
    el.style.transition = "";
    el.style.top= "";
    el.style.left= "";
    classPinTab.forEach((cl)=>{
      el.classList.remove(cl);
      el.setAttribute('data-pin', "0");
    });
  },500);
}
function reduceFloatMenu(e){
  zIndex++;
  var oc = volt.tak('outils-content-'+e.target.getAttribute('data-id'));
  var el = volt.tak('detail-content-'+e.target.getAttribute('data-id'));
  var flm = volt.tak('menu-float-'+e.target.getAttribute('data-id'));
  var tb = volt.tak('top-bar-menu-float-'+e.target.getAttribute('data-id'));
  var ic = volt.tak('icon-reduce-menu-float-content-'+e.target.getAttribute('data-id'));
  var dc = volt.tak('menu-float-content-'+e.target.getAttribute('data-id'));
  flm.style.zIndex = zIndex;
  if(ic.className === "fas fa-minus"){
    oc.style.display = "none";
    el.style.height= "0";
    el.style.padding= "0";
    tb.style.width = "200px";
    dc.style.border = 'none';
    setTimeout(()=>{
      flm.style.width = "300px";
      if(flm.getAttribute('data-pin') === "1"){
        epingleFloatMenu(e);
      }
    },500);
    ic.className = "fas fa-angle-down";
  }else{
    if(flm.getAttribute('data-pin') === "1"){
      classPinTab.forEach((cl)=>{
        flm.classList.remove(cl);
      });
      flm.style.top = parseInt(flm.style.top) + 30 + 'px';
    }
    if(el.getAttribute('data-height') !== undefined && el.getAttribute('data-height') !== null){
      el.style.height= el.getAttribute('data-height');
    }else{
      el.style.height= "";
    }
    if(el.getAttribute('data-width') !== undefined && el.getAttribute('data-width') !== null){
      el.style.width= el.getAttribute('data-width');
    }else{
      el.style.width= "";
    }
    tb.style.width = ""
    flm.style.width = "";
    ic.className = "fas fa-minus";
    el.style.padding= "";
    dc.style.border = '';
    setTimeout(()=>{
      oc.style.display = "";
      var diffAffY = (window.innerHeight - e.clientY);
      var diffAffX = (window.innerWidth - e.clientX);
      if(diffAffY < flm.scrollHeight){
        var inter1 = setInterval(()=>{
          flm.style.top = parseInt(flm.style.top) - 50 + 'px';
          if(parseInt(flm.style.top) <= 50 || parseInt(flm.style.top) <= window.innerHeight - flm.scrollHeight - 70){
            clearInterval(inter1);
          }
        }, 0.005);
      }
      if(diffAffX < flm.scrollWidth){
        var inter2 = setInterval(()=>{
          flm.style.left = parseInt(flm.style.left) - 50 + 'px';
          if(parseInt(flm.style.left) < 100 || parseInt(flm.style.left) <= window.innerWidth - flm.scrollWidth - 50){
            clearInterval(inter2);
          }
        }, 0.005);
      }
    },500);
  }
}
function epingleFloatMenu(e){
  volt.tak('epingle-menu-float-'+e.target.getAttribute('data-id')).style.display = 'none';
  volt.tak('pointe-menu-float-'+e.target.getAttribute('data-id')).style.display = 'block';
  var ic = volt.tak('icon-reduce-menu-float-content-'+e.target.getAttribute('data-id'));
  var el = volt.tak('menu-float-'+e.target.getAttribute('data-id'));
  if(el.getAttribute('data-pin') === "0" || el.getAttribute('data-pin') === null) {
    if (ic.className === "fas fa-minus") {
      reduceFloatMenu(e);
    }
  }
  var utilPinTab = [];
  var utilLeftTab = [];
  var inutilPinTab = [];
  var inutilLeftTab = [];
  classPinTab.forEach((cl, index)=>{
    volt.taks('.'+cl, '', false).forEach((e)=>{
      utilPinTab.push(cl);
      utilLeftTab.push(index);
    })
  });
  classPinTab.forEach((cl, index)=> {
    if(!utilPinTab.includes(cl)){
      inutilPinTab.push(cl)
    }
    if(!utilLeftTab.includes(index)){
      inutilLeftTab.push(index)
    }
  });
  if (el.style.top !== "10px") {
    countPinMenu++;
    el.style.top = "10px";
    el.style.left = tabValLeft[inutilLeftTab[0]];
    el.setAttribute('data-pin', "1");
    el.classList.add(inutilPinTab[0])
    el.style.boxShadow = "none";
  }
  // }
}
</script>

<style scoped>
.pin1{
  left: 87px !important;
}
.pin2{
  left: 389px !important;
}
.pin3{
  left: 691px !important;
}
.pin4{
  left: 993px !important;
}
.pin5{
  left: 1295px !important;
}
.pin6{
  left: 1597px !important;
}
.pin7{
  left: 87px !important;
}
.menu-float{
  position: fixed;
  top: 0;
  left: 0;
  transform: scale(0);
  /*width: 800px;*/
  background-color: #BBBBBEdd;
  /*border-radius: 20px;*/
  height: 100%;
  box-shadow: 1px 1px 5px #212121;
  z-index: 1000;
  width: 100%;
}
.menu-float-content{
  width: 100%;
  /*background-color: #BBBBBEdd;*/
  /*background-color: transparent;*/
  transition: 0.7s;
  border-radius: 10px;
  margin-top: 25px;
  /*border: solid 1px #f3b61b;*/
}
.detail-content-floatmenu{
 height: 800px;
}
.menu-float-top-bar{
  /*display: inline-block;*/
  display: none;
  color: #dde3df;
  width: 80%;
  font-size: 16px;
  background-color: #2e3133;
  padding-left: 10px;
  padding-top: 3px;
  cursor: pointer;
  border-radius: 20px;
  transition: 0.5s;
  height: 91%;
  text-align: start;
  float: left;
}
.closed-menu-float{
  /*display: inline-block;*/
  /*float: right;*/
  font-size: 25px;
  /*padding: 0 5px 0 5px;*/
  /*border-left: solid 1px #BBBBBB;*/
  width: 100%;
}
.pointe-menu{
  display: none;
}
.fa-times-circle{
  cursor: pointer;
  font-size: 25px;
  float: right;
  /*color: rgb(62, 141, 66);*/
}
/*.fas{*/
/*  cursor: pointer;*/
/*  font-size: 25px;*/
/*  !*color: rgb(62, 141, 66);*!*/
/*}*/
.outils-contant-float{
  display: flex;
  flex-direction: column;
  /*flex-wrap: wrap;*/
  justify-content: space-between;
  text-align: start;
  /*display: grid;*/
  /*grid-template-columns: repeat(20, 1fr);*/
  /*grid-gap: 1px;*/
  /*grid-template-rows: repeat(20, 1fr);*/
  height: 99%;
  max-height: 99%;
  background-color: #fefefe;
  border-radius: 10px;
  margin: 0 10px 5px 10px;
  /*overflow: hidden;*/
  padding: 5px;
  border: solid 1px #f3b61b;
  overflow: auto;
}
.details-table-annonce{
  grid-column-start: 9;
  grid-column-end: 21;
  grid-row-start: 1;
  grid-row-end: 12;
  background-color: #7a7a7a;
  border-radius: 0 10px 0 0;
}
.entete-pri{
  color: #f3b61b;
  padding: 5px;
  background-color: #7a7a7a;
  border-radius: 5px;
  margin: 3px;
  width: 100%;
}
.entete-choice{
  color: #0e7f65;
  padding: 5px;
  background-color: rgba(117, 118, 117, 0.47);
  border-radius: 5px;
  margin: 3px;
}
.div-btn-choice-entete{
  /*display: flex;*/
  /*flex-direction: row;*/
  /*flex-wrap: wrap;*/
  /*justify-content: start;*/
}
#div-btn-choices-field{
  padding: 2px;
  overflow: auto;
  height: 84%;
  margin: 5px;
  box-shadow: inset 1px 1px 5px #7a7a7a;
  border-radius: 5px;
  /*overflow: auto !important;*/
}
.entete-choice-updates{
  color: #232323;
  padding: 5px;
  /*background-color: #f4b587;*/
  border-bottom: solid 1px #f4b587;
  /*border-radius: 5px;*/
  margin: 3px;
  cursor: pointer;
}
.entete-choice-updates:hover{
  background-color: #f3b61b55;
}
.entete-choice-updates:active{
  box-shadow: inset 1px 1px 5px #2a2323;
  background-color: #f3751b;
  color: #232222;
}
.entete-choice-properties{
  color: #232323;
  padding: 5px;
  /*background-color: #8ff1f4;*/
  border-bottom: solid 1px #094490;
  /*border-radius: 5px;*/
  margin: 3px;
  cursor: pointer;
}
.entete-choice-properties:hover{
  background-color: #f3b61b55;
}
.entete-choice-properties:active{
  box-shadow: inset 1px 1px 5px #1c1717;
  background-color: #0eb6f3;
  color: #232222;
}
.title-menu-params{
  padding: 5px;
  width: 80%;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 5px;
  border-bottom: solid 2px #f3b61b;
}

.colonne-number{
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: bold;
}
.div-entete-position{
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 20px;
  max-height: 430px;
  overflow: auto;
}
.loader-entete-position{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 15px;
}
.entete-select-list{
  display: flex;
  flex-direction: column;
  padding: 10px;
  /*box-shadow: 1px 1px 5px #7a7a7a;*/
  outline: solid 1px #7a7a7a55 ;
  margin: 5px 5px 5px 5px;
  border-radius: 5px;
  width: 23%;
}
.entete-select-list:active{
  /*box-shadow: none;*/
  /*outline: solid 1px #7a7a7a55;*/
}
.entete-select-list-add{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  box-shadow: 1px 1px 5px #7a7a7a;
  margin: 5px;
  border-radius: 10px;
  cursor: pointer;
}
.entete-select-list-add:active{
  box-shadow: none;
  outline: solid 1px #7a7a7a55;
}
.fa-plus-square{
  /*font-size: 10px;*/
}
.label-input-form{
  display: inline-block;
  width: 25%;
  margin-right: 10px;
  text-align: right;
  padding-top: 5px;
}
.label-input-form-size{
  display: inline-block;
  width: 25%;
  margin-right: 10px;
  text-align: right;
  padding-top: 13px;
}
.label-input-div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}
.entete-input-name{
  width: 60%;
  font-size: 18px;
  /*width: 120px;*/
  /*margin-top: 10px;*/
  border: none;
  outline: solid 1px #7a7a7a55;
  /*box-shadow: inset 1px 1px 5px #7a7a7a;*/
  padding:  5px 10px 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.entete-input-size{
  /*min-width: 120px;*/
  width: 60%;
  margin-top: 10px;
  border: none;
  outline: solid 1px #7a7a7a55;
  /*box-shadow: inset 1px 1px 5px #7a7a7a;*/
  text-align: center;
  padding:  5px 10px 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.entete-choice-position{
  display: flex;
  justify-content: space-between;
}
.fa-pen-alt {
  float: right;
  cursor: pointer;
  outline: solid 1px #7a7a7a55;
  padding: 3px;
  border-radius: 5px;
  margin-left: 5px;
}
.fa-pen-alt:active {
  box-shadow: inset 1px 1px 5px #7a7a7a;
}
.fa-trash-alt{
  float: right;
  cursor: pointer;
  outline: solid 1px #7a7a7a55;
  padding: 3px;
  border-radius: 5px;
}
.fa-trash-alt:active {
  box-shadow: inset 1px 1px 5px #7a7a7a;
}
.div-valid-list{
  margin: 0.5%;
  /*padding: 10px;*/
  display: flex;
  justify-content: space-between;
  width: 99%;
}
.div-btn-list{
  display: flex;
  justify-content: start;
  width: 50%;
}
.div-btn-list-2{
  display: flex;
  justify-content: end;
  width: 50%;
}
.valid-list{
  font-size: 20px;
  /*padding: 5px 15px 5px 15px;*/
  /*border: none;*/
  /*border-radius: 5px;*/
  /*box-shadow: 1px 1px 5px #7a7a7a;*/
  /*cursor: pointer;*/
  margin: 5px;
  text-align: center;
  /*width: 300px;*/
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  background-color: #d6d6d6;
}
.valid-list:active{
  box-shadow: inset 1px 1px 5px #7a7a7a;
  /*box-shadow: none;*/
  /*outline: solid 1px #7a7a7a55;*/
}
.drop-target{
  position: relative;
  left: 104%;
  top: -100%;
  background-color: #0e7f65;
  width: 5px;
  height: 100px;
}
.pop-field-choice{
  width: 350px;
  height: 0;
  box-shadow: 1px 1px 5px #7a7a7a;
  background-color: #e7e7e7;
  border-radius: 10px;
  position: fixed;
  top: 20%;
  left: 40%;
  z-index: 1000;
  transition: 0.4s;
  overflow: auto;
}
.pop-col-select{
  /*width: 300px;*/
  height: 0;
  box-shadow: 1px 1px 5px #7a7a7a;
  background-color: #e7e7e7;
  border-radius: 10px;
  position: fixed;
  top: 20%;
  left: 40%;
  z-index: 1000;
  transition: 0.4s;
}
#pop-sub-col-select{
  display: none;
}
.col-png-select{
  /*width: 100%;*/
  height: 50px;
  /*background-color: #0e7f65;*/
}
.col1{
  /*width: 80%;*/
  /*height: 30px;*/
}
.col2{
  /*width: 80%;*/
  /*height: 80%;*/
}
.col3{
  /*width: 80%;*/
}
.col4{
  /*width: 80%;*/
}
.col5{
  /*width: 80%;*/
}
.div-col-select {
  margin: 10px;
  /*width: 160px;*/
  width: 175px;
  height: 50px;
  /*padding: 10px;*/
  /*border: solid 1px red;*/
  border-radius: 5px;
  background-color: #f0f1f1;
  box-shadow: 1px 1px 5px #7a7a7a;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: start;
}
.div-col-select:active{
  box-shadow: none;
  outline: solid 1px #7a7a7a77;
}
.div-nbr-select{
  margin-top: 30px;
  padding: 5px;
  width: 30px;
  font-size: 12px;
  /*border-radius: 10px;*/
  /*border: solid 1px #7a7a7a;*/
}
.label-rapport-title{
  width: 99%;
  margin: 10px;
  display: flex;
  justify-content: start;
  height: 50px;
  align-items: center;
}
.label-input-rapport-title{
  margin-right: 10px;
  font-weight: bold;
}
#rapport-title{
  border: none;
  background-color: #BBBBBE33;
  font-size: 18px;
  border-radius: 5px;
  width: 500px;
  padding: 5px 10px 5px 10px ;
}
.help-rapport-create{
  margin-left: 10px;
}
.btn-save-rapport{
  background-color: #f3b61b;
}
.btn-help-rapport{
  background-color: #f3b61b;
  width: 100px;
  min-height: 40px;
}
.error-msg-list-creat{
  width: 600px;
  color: #7a0202;
  background-color: #ed667a;
  display: flex;
  justify-content: center;
  margin-left: 100px;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  font-weight: 600;
}
</style>