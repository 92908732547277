<template>
  <div class="kpi-list">

    <div v-if="style.visuel === 'block'" class="visuel-brick visuel-block">
      <SimpleBlock
          :title="title"
          :kpis="kpis"
          :style="style"
          :value="value"
          :link="link"
          :limit="limit"
          :year = "year"
          :evolution="evolution"
          :versus="versus"
          @callLinkSec="callLinkSec"
          @callLinkPri="callLinkPri"
      />
    </div>

    <div v-if="style.visuel === 'block-multi' || style.visuel === 'multi-block'" class="visuel-brick visuel-block-multi" :style="'background-color:'+backcolor">
      <ClusterBlock v-if="link.type === 'cluster'"
          :title="title"
          :kpis="kpis"
          :style="style"
          :value="value"
          :link="link"
          :limit="limit"
          :year = "year"
          @callLinkSec="callLinkSec"
          @callLinkPri="callLinkPri"
      />
      <MultiBlock v-else
          :title="title"
          :kpis="kpis"
          :style="style"
          :value="value"
          :link="link"
          :limit="limit"
          :year = "year"
          @callLinkSec="callLinkSec"
          @callLinkPri="callLinkPri"
      />
    </div>

    <div v-if="style.visuel === 'geauge'" class="visuel-brick visuel-geauge">
      <JaugeLine
          :title="title"
          :kpis="kpis"
          :style="style"
          :value="value"
          :link="link"
          :limit="limit"
          :year = "year"
          @callLinkSec="callLinkSec"
          @callLinkPri="callLinkPri"
      />
    </div>

    <div v-if="style.visuel === 'block-graph'" class="visuel-brick visuel-block-graph">
      <BlockGraph
          :title="title"
          :kpis="kpis"
          :style="style"
          :link="link"
          :limit="limit"
          :year = "year"
          :evolution="evolution"
          @callLinkSec="callLinkSec"
          @callLinkPri="callLinkPri"
      />
    </div>

    <div v-if="style.visuel === 'graph-line'" class="visuel-brick visuel-graph-line">
     <LineBlock :title="title" :datas="kpis" :style="style" type="simple"/>
    </div>

    <div v-if="style.visuel === 'graph-multi-line'" class="visuel-brick visuel-graph-line">
      <LineBlock :title="title" :datas="kpis" :style="style" type="multi" />
    </div>

    <div v-if="style.visuel === 'graph-histograme'" class="visuel-brick visuel-graph-histograme">
      <HistoBlock
          :title="title"
          :datas="kpis"
          :link="link"
          :limit="limit"
          :year = "year"
          :style="style"
          @callLinkPri="callLinkPri"
          type="simple"/>
    </div>
    <div v-if="style.visuel === 'graph-histograme-horizontal'" class="visuel-brick visuel-graph-histograme">
      <HistoBlockHorizontal
          :title="title"
          :datas="kpis"
          :link="link"
          :limit="limit"
          :year = "year"
          :style="style"
          @callLinkPri="callLinkPri"
          type="simple"/>
    </div>
    <div v-if="style.visuel === 'graph-multi-histograme'" class="visuel-brick visuel-graph-histograme">
      <HistoBlock :title="title" :datas="kpis" :style="style" type="multi" @callLinkPri="callLinkPri"/>
    </div>

    <div v-if="style.visuel === 'map'" :data-group="link.group" class="visuel-brick visuel-map">
      <div class="kpi-subtitle-map" :style="style.title">{{title_}}</div>
      <MapsVue
          :title = "link.type"
          gridCS = "1"
          gridCE = "21"
          gridRS = "2"
          gridRE = "21"
          @click = "callMap"
      />
    </div>

    <div v-if="style.visuel === 'jauge'" class="visuel-brick visuel-jauge">
      <JaugeRadius
          :title="title"
          :kpis="kpis"
          :style="style"
          :value="evolution"
          :link="link"
          :limit="limit"
          :year = "year"
          @callLinkSec="callLinkSec"
          @callLinkPri="callLinkPri"
      />
    </div>

    <div v-if="style.visuel === 'graph-doughnut'" class="visuel-brick visuel-graph-doughnut">
      <div class="kpi-subtitle" :style="style.title">{{title}}</div>
    </div>

  </div>
</template>

<script>
// import volt from "../volt";
import MultiBlock from "@/elements/MultiBlock";
import JaugeLine from "@/elements/JaugeLine";
import ClusterBlock from "@/elements/ClusterBlock";
import SimpleBlock from "@/elements/SimpleBlock";
import MapsVue from "@/components/MapsVue";
// import olMap from "@/olMap";
import ol_map from "@/ol_map";
import volt from "@/volt";
import olFunction from "@/olFunction";
import HistoBlock from "@/elements/HistoBlock";
import LineBlock from "@/elements/LineBlock";
import JaugeRadius from "@/elements/JaugeRadius";
import BlockGraph from "@/elements/BlockGraph";
import list from "@/services/listes";
import HistoBlockHorizontal from "@/elements/HistoBlockHorizontal";
export default {
  name: "KpiListCO",
  components : {
    HistoBlockHorizontal,
    BlockGraph,
    JaugeRadius, LineBlock, HistoBlock, MapsVue, SimpleBlock, ClusterBlock, JaugeLine, MultiBlock},
  emits: ["callList", "callMap"],
  props:{
    limit : Number,
    title : [String, Object],
    kpis : [Array],
    value : [String, Number],
    style : Object,
    link : Object,
    size : String,
    year : String,
    evolution : [String, Number],
    versus : Object,
    datasMap : Object,
    map_filter : Number,
    entity : String
  },
  mounted() {
    this.title_ = this.title[this.map_filter.toString()]
    if(Object.keys(this.kpis).length > 0){
      this.weigthTitle = 600;
      this.margBotEle = '2px';
    }
    if(this.style.visuel === "map"){
      if (volt.tak('map-' + this.link.type)) {
        volt.tak('map-' + this.link.type).innerHTML = '';
      }
      if(this.link.type === 'zoom-iris'){
        setTimeout(() => {
          this.maps[this.link.type] = {};
          this.maps[this.link.type] = ol_map.callMap(this.link.type);
          this.maps[this.link.type].addLayer(olFunction.callLayerEntOlPolygone(volt.o(this.datasMap.entity_polygon), true, 'inactif'));
          list.detailIris(volt.feature, this.entity, this.year).then((irisMap)=>{
            this.irisMap = irisMap;
            this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.irisMap.iris), true, 'inactif');
            this.maps[this.link.type].addLayer(this.layer);
            let layerAnnoncesConf = olFunction.callLayer(volt.o(this.irisMap.layers['conforme']), 'true', 'icone', 'conforme',100);
            let layerAnnoncesNconf = olFunction.callLayer(volt.o(this.irisMap.layers['non_conforme']), 'true', 'icone', 'non_conforme',200);
            let layerAnnoncesAVerif = olFunction.callLayer(volt.o(this.irisMap.layers['a_verifier']), 'true', 'icone', 'a_verifier',300);
            this.maps[this.link.type].addLayer(layerAnnoncesConf);
            this.maps[this.link.type].addLayer(layerAnnoncesNconf);
            this.maps[this.link.type].addLayer(layerAnnoncesAVerif);
            olFunction.mapSetViewFeature(this.maps[this.link.type], 14, volt.o(this.irisMap.center));
          })
        }, 200);
      }else if(this.link.type === 'zoom-arrondissement'){
        setTimeout(() => {
          this.maps[this.link.type] = {};
          this.maps[this.link.type] = ol_map.callMap(this.link.type);
          this.maps[this.link.type].addLayer(olFunction.callLayerEntOlPolygone(volt.o(this.datasMap.entity_polygon), true, 'inactif'));
          list.detailArrondissement(volt.feature, this.entity, this.year).then((arrMap)=>{
            this.arrMap = arrMap;
            this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.arrMap.iris), true, 'inactif');
            this.maps[this.link.type].addLayer(this.layer);
            let layerAnnoncesConf = olFunction.callLayer(volt.o(this.arrMap.layers['conforme']), 'true', 'icone', 'conforme',100);
            let layerAnnoncesNconf = olFunction.callLayer(volt.o(this.arrMap.layers['non_conforme']), 'true', 'icone', 'non_conforme',200);
            let layerAnnoncesAVerif = olFunction.callLayer(volt.o(this.arrMap.layers['a_verifier']), 'true', 'icone', 'a_verifier',300);
            this.maps[this.link.type].addLayer(layerAnnoncesConf);
            this.maps[this.link.type].addLayer(layerAnnoncesNconf);
            this.maps[this.link.type].addLayer(layerAnnoncesAVerif);
            olFunction.mapSetViewFeature(this.maps[this.link.type], 14, volt.o(this.arrMap.center));
          })
        }, 200);
      }else if(this.link.type === 'zoom-ept'){
        setTimeout(() => {
          this.maps[this.link.type] = {};
          this.maps[this.link.type] = ol_map.callMap(this.link.type);
          this.maps[this.link.type].addLayer(olFunction.callLayerEntOlPolygone(volt.o(this.datasMap.entity_polygon), true, 'inactif'));
          list.detailEntity(volt.feature, this.entity, this.year).then((arrMap)=>{
            this.arrMap = arrMap;
            this.layer = olFunction.callLayerTourizOlPolygone(volt.o(this.arrMap.iris), true, 'inactif');
            this.maps[this.link.type].addLayer(this.layer);
            let layerAnnoncesConf = olFunction.callLayer(volt.o(this.arrMap.layers['conforme']), 'true', 'icone', 'conforme',100);
            let layerAnnoncesNconf = olFunction.callLayer(volt.o(this.arrMap.layers['non_conforme']), 'true', 'icone', 'non_conforme',200);
            let layerAnnoncesAVerif = olFunction.callLayer(volt.o(this.arrMap.layers['a_verifier']), 'true', 'icone', 'a_verifier',300);
            this.maps[this.link.type].addLayer(layerAnnoncesConf);
            this.maps[this.link.type].addLayer(layerAnnoncesNconf);
            this.maps[this.link.type].addLayer(layerAnnoncesAVerif);
            olFunction.mapSetViewFeature(this.maps[this.link.type], 14, volt.o(this.arrMap.center));
          })
        }, 200);
      }else {
        setTimeout(() => {
          this.maps[this.link.type] = {};
          let zoom = 13;
          if (this.style.back === 'empty') {
            if(this.map_filter === 3){
              this.maps[this.link.type] = ol_map.callMap(this.link.type, '', 10, 'empty');
              zoom = 10;
            }else{
              this.maps[this.link.type] = ol_map.callMap(this.link.type, '', 11, 'empty');
              zoom = 12;
            }
          } else {
            if(this.map_filter === 3) {
              this.maps[this.link.type] = ol_map.callMap(this.link.type, '', 10);
              zoom = 10;
            }else {
              this.maps[this.link.type] = ol_map.callMap(this.link.type);
            }
          }
          let layer = {};
          if (this.datasMap.iris && this.map_filter === 1) {
            this.maps[this.link.type].addLayer(olFunction.callLayerEntOlPolygone(volt.o(this.datasMap.entity_polygon), true, 'inactif'));
            if (this.style.back === 'empty') {
              layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.iris_top), true);
            } else {
              layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.iris), true);
            }
            this.maps[this.link.type].addLayer(layer);
            olFunction.mapSetViewFeature(this.maps[this.link.type], zoom, volt.o(this.datasMap.center));
          }else if (this.datasMap?.arrondissements?.features && this.map_filter === 2) {
            this.maps[this.link.type].addLayer(olFunction.callLayerEntOlPolygone(volt.o(this.datasMap.entity_polygon), true, 'inactif'));
            if(this.style.back === 'empty'){
              layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.arrondissements_top), true);
            }else{
              layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.arrondissements), true);
            }
            this.maps[this.link.type].addLayer(layer);
            olFunction.mapSetViewFeature(this.maps[this.link.type], zoom, volt.o(this.datasMap.center));
          }else if (this.datasMap?.entities?.features && this.map_filter === 3) {
            this.maps[this.link.type].addLayer(olFunction.callLayerEntOlPolygone(volt.o(this.datasMap.entity_polygon), true, 'inactif'));
            if(this.style.back === 'empty'){
              layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.entities_top), true);
            }else{
              layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.entities), true);
            }
            this.maps[this.link.type].addLayer(layer);
            olFunction.mapSetViewFeature(this.maps[this.link.type], zoom, volt.o(this.datasMap.center));
          }
        }, 200);
      }
    }
    if(this.style.value){
      if(this.style.value.color === 'green'){
        this.backcolor = '#b9e4c1';
      } else if(this.style.value.color === 'orange'){
        this.backcolor = '#f1de9e';
      } else if(this.style.value.color === 'red'){
        this.backcolor = '#eeb690';
      } else{
        this.backcolor = this.style.value.color;
      }
    }
    if(this.link.type === 'geauge'){
      this.jauge_elem =[];
      for(let kpi in this.kpis){
        for(let kp in this.kpis[kpi].value){
          let val = this.kpis[kpi].value[kp];
          let color = this.kpis[kpi].style.value["color"];
          this.jauge_elem.push({
            "width" : (val*100/ this.value)+'%',
            "value" : (val*100/ this.value).toFixed(2),
            "background-color" :color,
            "data-name" :this.kpis[kpi].link.name,
            "data-link" : this.kpis[kpi].link.value,
            "data-size" : this.kpis[kpi].link.size,
            "data-type": this.kpis[kpi].link.type,
            "radius" : "0"
          })
        }
      }
      this.jauge_elem[0]['radius'] =  "10px 0 0 10px";
      this.jauge_elem[(this.jauge_elem.length - 1)]['radius'] = "0 10px 10px 0";
    }
  },
  watch:{
    map_filter(){
      if(this.style.visuel === "map") {
        this.title_ = this.title[this.map_filter.toString()]
        if (volt.tak('map-' + this.link.type)) volt.tak('map-' + this.link.type).innerHTML = '';
        setTimeout(() => {
          this.maps[this.link.type] = {};
          let zoom = 13;
          if (this.style.back === 'empty') {
            if(this.map_filter === 3){
              this.maps[this.link.type] = ol_map.callMap(this.link.type, '', 10, 'empty');
              zoom = 10;
            }else{
              this.maps[this.link.type] = ol_map.callMap(this.link.type, '', 11, 'empty');
              zoom = 12;
            }
          } else {
            if(this.map_filter === 3) {
              this.maps[this.link.type] = ol_map.callMap(this.link.type, '', 10);
              zoom = 10;
            }else {
              this.maps[this.link.type] = ol_map.callMap(this.link.type);
            }
          }
          this.maps[this.link.type].addLayer(olFunction.callLayerEntOlPolygone(volt.o(this.datasMap.entity_polygon), true, 'inactif'));
          let layer = {};
          if (this.datasMap?.arrondissements?.features && this.map_filter === 2) {
            if(this.style.back === 'empty'){
              layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.arrondissements_top), true);
            }else{
              layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.arrondissements), true);
            }
            this.maps[this.link.type].addLayer(layer);
            olFunction.mapSetViewFeature(this.maps[this.link.type], zoom, volt.o(this.datasMap.center));
          } else if (this.datasMap.iris && this.map_filter === 1) {
            if(this.style.back === 'empty'){
              layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.iris_top), true);
            }else{
              layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.iris), true);
            }
            this.maps[this.link.type].addLayer(layer);
            olFunction.mapSetViewFeature(this.maps[this.link.type], zoom, volt.o(this.datasMap.center));
          }else if (this.datasMap?.entities?.features && this.map_filter === 3) {
            this.maps[this.link.type].addLayer(olFunction.callLayerEntOlPolygone(volt.o(this.datasMap.entity_polygon), true, 'inactif'));
            if(this.style.back === 'empty'){
              layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.entities_top), true);
            }else{
              layer = olFunction.callLayerTourizOlPolygone(volt.o(this.datasMap.entities), true);
            }
            this.maps[this.link.type].addLayer(layer);
            olFunction.mapSetViewFeature(this.maps[this.link.type], zoom, volt.o(this.datasMap.center));
          }
        }, 200);
      }
    },
    kpis(){
      if(this.link.type === 'geauge'){
        this.jauge_elem =[];
        for(let kpi in this.kpis){
          for(let kp in this.kpis[kpi].value){
            let val = this.kpis[kpi].value[kp];
            let color = this.kpis[kpi].style.value["color"];
            this.jauge_elem.push({
              "width" : (val*100/ this.value)+'%',
              "background-color" :color,
              "data-name" :this.kpis[kpi].link.name,
              "data-link" : this.kpis[kpi].link.value,
              "data-size" : this.kpis[kpi].link.size,
              "data-type": this.kpis[kpi].link.type,
              "radius" : "0"
            })
          }
        }
        this.jauge_elem[0]['radius'] =  "10px 0 0 10px";
        this.jauge_elem[(this.jauge_elem.length - 1)]['radius'] = "0 10px 10px 0";
      }
    }
  },
  data(){
    return{
      jauge_elem : [],
      weigthTitle : 0,
      margBotEle : '2px',
      backcolor : "",
      maps : {},
      title_ : "",
      irisMap : {},
      arrMap : {}
    }
  },
  methods:{
    callMap(){
      setTimeout(()=>{ this.$emit('callMap');},200);
    },
    callLinkPri(){
      this.$emit('callList');
    },
    callLinkSec(){
      this.$emit('callList');
    }
  }
}
</script>

<style scoped>
.kpi-list{
  padding: 5px;
  display: grid;
  grid-column-start: v-bind(style['gridCS']);
  grid-column-end: v-bind(style['gridCE']);
  margin-bottom: v-bind(margBotEle);
}
.visuel-brick{
  border: solid 1px #7a7a7a;
  border-radius: 10px;
  height: 200px;
  overflow: auto;
}
.visuel-block{
  /*cursor: pointer;*/
}
.visuel-map{
  height:500px;
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-template-rows: repeat(20, 1fr);
}
.visuel-map-calque{
  height:400px;
  background-color: transparent;
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-template-rows: repeat(20, 1fr);
}
.visuel-block-graph{

}
.visuel-graph-line{

}
.visuel-block-multi{

}
.visuel-jauge{

}
.visuel-graph-doughnut{

}
.kpi-subtitle{
  text-align: start;
  font-size: 14px;
  font-weight: bold;
  padding: 15px;
  display: flex;
  flex-direction: row;
  height: 30%;
}
.kpi-subtitle-map{
  text-align: start;
  font-size: 14px;
  font-weight: bold;
  padding: 15px;
  grid-column-start: 1;
  grid-column-end: 21;
  grid-row-start: 1;
  grid-row-end: 2;
}
@media (max-width: 1250px) {
  .visuel-brick{
    height: 150px;
    padding-bottom: 10px;
  }
  .visuel-block-multi{
    height: max-content;
    padding-bottom: 10px;
  }
  .visuel-map{
    height: 300px;
  }
  .visuel-graph-histograme{
    height: max-content;
  }
  .visuel-graph-line{
    height: max-content;
  }
}
@media (max-width: 800px) {
  .kpi-list{
    padding: 5px;
    display: grid;
    grid-column-start: 1;
    grid-column-end: 13;
    margin-bottom: v-bind(margBotEle);
  }
  .visuel-brick{
    height: max-content;
    padding-bottom: 10px;
  }
  .visuel-map{
    height: 300px;
  }
  .visuel-block-multi{
    height: max-content;
  }
  .visuel-geauge{
    height: max-content;
  }
  .visuel-graph-histograme{
    height: max-content;
  }
}
</style>