const extent = require('ol/extent');
const {Vector : VectorLayer} =require("ol/layer");
const {Vector : VectorSource, Cluster} =require("ol/source");
const {Style, Circle, Fill, Stroke, Text, Icon} =require("ol/style");
const {GeoJSON} =require("ol/format");
const {transform} =require("ol/proj");
const {transformExtent} =require("ol/proj");
const ol = require("@/olMap");
const volt = require("@/volt");
/* eslint-disable */
function getStyleGeoJsonPoint(form , color, size){
    var style = new Style({
        image: new Circle({
            radius: 5,
            fill: new Fill({color: '#000000'}),
            stroke: new Stroke({color: '#000000', width: 1})
        }),
    });
    if(form === 'point'){
        style = new Style({
            // text: new Text({
            //     text:'+',
            // }),
            image: new Circle({
                radius: 10,
                // fill: new Fill({color: color+ '77'}),
                fill: new Fill({color: color}),
                stroke: new Stroke({color: '#000000' , width: 2})
            }),
        });
    }
    if(form === 'icone'){
        let icone = 'icone_green2.png';
        let icone_cluster = 'icone_green_cluster2.png';
        if(color === 'conforme'){
            icone = 'icone_green2.png';
            icone_cluster = 'icone_green_cluster2.png';
        }
        if(color === 'a_verifier'){
            icone = 'icon_orange.png';
            icone_cluster = 'icon_orange_cluster.png';
        }
        if(color === 'non_conforme'){
            icone = 'icon_red.png';
            icone_cluster = 'icon_red_cluster.png';
        }
        if(color === 'google-map'){
            icone = 'google-maps.png';
            style = new Style({
                image: new Icon({
                    // size : [ 40 , 60 ] ,
                    // anchor: [0, 0.9],
                    scale: 0.02,
                    opacity: 1,
                    src: icone,
                }),
            });
        }else {
            if (size.toString() === '1') {
                style = new Style({
                    image: new Icon({
                        // size : [ 40 , 60 ] ,
                        anchor: [0, 0.9],
                        scale: 1,
                        opacity: 1,
                        // src: 'housse_bleu.png',
                        src: icone,
                    }),
                    // zIndex : 5
                });
            } else {
                style = new Style({
                    text: new Text({
                        text: size.toString(),
                        // offsetY : -5,
                        // offsetX : 10,
                        font: 'lighter 20px sans-serif',
                        stroke: new Stroke({color: 'rgb(78,10,2)', width: 0}),
                        fill: new Fill({
                            color: '#000000'
                        })
                    }),
                    image: new Icon({
                        // size : [ 40 , 60 ] ,
                        // anchor: [0.5,1],

                        scale: 0.7,
                        opacity: 1,
                        src: icone_cluster,
                        // src: 'icone_test.png',
                    }),
                    // zIndex : 9
                });
            }
        }

    }
    return style;
}
module.exports.getStyleGeoJsonPolygon = function(nbr,color = '',text = ''){
    var style = '';
    if(color !== ''){
        style = new Style({
            text: new Text({
                text:text,
                font:'lighter 13px sans-serif',
                stroke: new Stroke({color: 'rgb(78,10,2)', width: 0}),
                // fill: new ol.style.Fill({color: 'rgb(78,10,2)'}),
            }),
            fill: new Fill({color: color+"55"}),
            stroke: new Stroke({color: color, width: 1}),
        });
    }else{
        if(nbr > 15)color = 'rgba(93, 17, 0, 0.6)';
        if(nbr > 10 && nbr <= 15)color = 'rgba(137, 62, 62, 0.6)';
        if(nbr > 5 && nbr <= 10)color = 'rgba(207, 93, 37, 0.6)';
        if(nbr > 2 && nbr <= 5)color = 'rgba(198, 147, 101, 0.6)';
        if(nbr > 0 && nbr <= 2)color = 'rgba(243, 211, 135, 0.6)';
        if(nbr <= 0)color = 'rgba(198, 198, 198, 0.6)';
        style = new Style({
            fill: new Fill({color: "rgba(93, 17, 0, 0.6)"}),
            stroke: new Stroke({color: 'rgba(93, 17, 0, 1)', width: 1}),
        });
    }
    return style;
}

module.exports.callLayer = function(geoJsonObject, visibility = 'false', form = '', color  = '', z_index) {
    if (geoJsonObject !== "") {
        this.transformProjection(geoJsonObject);
        var sourceBddLayer = this.createVectorSourceOl(geoJsonObject);
        const clusterSource = new Cluster({
            distance: parseInt("20"),
            minDistance: parseInt("10"),
            source: sourceBddLayer,
        });
        const styleCache = {};
        let zIndex = z_index;
        var bddLayer = new VectorLayer({
            visible: visibility,
            // preload: Infinity,
            zIndex: 1000,
            // source: sourceBddLayer,
            source: clusterSource,
            style: function (feature) {
                const size = feature.get('features').length;
                let style = styleCache[size];
                if (!style) {
                    style = getStyleGeoJsonPoint(form, color, size);
                    styleCache[size] = style
                }
                style.setZIndex(zIndex++);
                return style;
            }
        });
        return bddLayer;
    }
}
module.exports.createVectorSourceOl = function(geoJsonObject){
    var vectorSource =new VectorSource({
        features: (new GeoJSON()).readFeatures(geoJsonObject)
    });

    return vectorSource;
}
module.exports.inversLayer = function(geoJsonObject){
    var geom = geoJsonObject.features[0].geometry.coordinates;
    var inversePolygon = this.getBounds();
    for (var i = 0; i < geom.length; i++) {
      var polygon = geom[i];
      var linearRing = polygon[0];
      inversePolygon.push(linearRing);
    }
    return inversePolygon;
}
module.exports.getBounds = function() {
    const extent = ol.mapOl.getView().calculateExtent(ol.mapOl.getSize())
    return transformExtent(extent, 'EPSG:3857', 'EPSG:4326')
}
module.exports.transformProjection = function(featuresTab, projectionOrigin = 'EPSG:4326', projectionSortie = 'EPSG:3857'){
    if(featuresTab.type === 'FeatureCollection') {
        for (var i = 0; i < featuresTab['features'].length; i++) {
            if (featuresTab.features[i].geometry.type === 'Polygon') {
                for (var j = 0; j < featuresTab.features[i].geometry.coordinates[0].length; j++) {
                    featuresTab.features[i].geometry.coordinates[0][j] = transform(featuresTab.features[i].geometry.coordinates[0][j], projectionOrigin, projectionSortie);
                }
            } else if (featuresTab.features[i].geometry.type === 'MultiPolygon') {
                for (var l = 0; l < featuresTab.features[i].geometry.coordinates.length; l++) {
                    for (j = 0; j < featuresTab.features[i].geometry.coordinates[l].length; j++) {
                        for (var k = 0; k < featuresTab.features[i].geometry.coordinates[l][j].length; k++) {
                            featuresTab.features[i].geometry.coordinates[l][j][k] = transform(featuresTab.features[i].geometry.coordinates[l][j][k], projectionOrigin, projectionSortie);
                        }
                    }
                }
            } else if (featuresTab.features[i].geometry.type === 'Circle') {

            } else {
                featuresTab.features[i].geometry.coordinates = transform(featuresTab.features[i].geometry.coordinates, projectionOrigin, projectionSortie);
            }

        }
    }else{
        if (featuresTab.geometry.type === 'Polygon') {
            for (var j = 0; j < featuresTab.geometry.coordinates[0].length; j++) {
                featuresTab.geometry.coordinates[0][j] = transform(featuresTab.geometry.coordinates[0][j], projectionOrigin, projectionSortie);
            }
        } else if (featuresTab.geometry.type === 'MultiPolygon') {
            for (var l = 0; l < featuresTab.geometry.coordinates.length; l++) {
                for (j = 0; j < featuresTab.geometry.coordinates[l].length; j++) {
                    for (var k = 0; k < featuresTab.geometry.coordinates[l][j].length; k++) {
                        featuresTab.geometry.coordinates[l][j][k] = transform(featuresTab.geometry.coordinates[l][j][k], projectionOrigin, projectionSortie);
                    }
                }
            }
        } else if (featuresTab.geometry.type === 'Circle') {

        } else {
            featuresTab.geometry.coordinates = transform(featuresTab.geometry.coordinates, projectionOrigin, projectionSortie);
        }
    }
}
module.exports.styleOnClick = function(feature, fill_color = 'rgba(131,37,207,0.6)',stroke_color = '#450466'){
    if(feature.getProperties().color !== undefined && fill_color === 'rgba(131,37,207,0.6)'){
        fill_color = feature.getProperties().color
    }
    if(feature.getProperties().stroke_color !== undefined && stroke_color === '#450466'){
        stroke_color = feature.getProperties().stroke_color
    }
    if(feature.getGeometry().getType() === 'Point'){
        return new Style({
            image: new Icon({
                // size : [ 20 , 20 ] ,
                anchor: [0.5,1],
                scale: 1,
                opacity: 1,
                src: 'media/icon/cible_vert_mapi.png',
            }),
        });
    }else if(feature.getGeometry().getType() === 'Polygon'){
        return new Style({
            fill: new Fill({color: fill_color}),
            stroke: new Stroke({color: stroke_color, width: 2}),
        })
    }else if(feature.getGeometry().getType() === 'MultiPolygon'){
        return new Style({
            fill: new Fill({color: fill_color}),
            stroke: new Stroke({color: stroke_color, width: 2}),
        })
    }
}
module.exports.styleModify = function(feature,stroke_color = '',fill_color = '',width = 2){
    if(stroke_color === ''){
        if(feature.getStyle() !== undefined && feature.getStyle() !== null){
            stroke_color = feature.getStyle().getStroke().color_
        }
    }
    if(fill_color === ''){
        if(feature.getStyle() !== undefined && feature.getStyle() !== null){
            fill_color = feature.getStyle().getFill().color_
        }
    }
    if(fill_color === '' || fill_color === null || fill_color === undefined){
        fill_color = '#CF5D2599';
    }
    if(stroke_color === '' || stroke_color === null || stroke_color === undefined){
        stroke_color = '#f84d4d';
    }
    if(feature.getGeometry().getType() === 'Point'){
        feature.setStyle(new Style({
            image: new Icon({
                // size : [ 20 , 20 ] ,
                anchor: [0.5,1],
                scale: 1,
                opacity: 1,
                src: 'media/icon/cible_vert_mapi.png',
            }),
        }));
    }else if(feature.getGeometry().getType() === 'Polygon'){
        feature.setStyle(new Style({
            fill: new Fill({color: fill_color}),
            stroke: new Stroke({color: stroke_color, width: width}),
        }))
    }else if(feature.getGeometry().getType() === 'MultiPolygon'){
        feature.setStyle(new Style({
            fill: new Fill({color: fill_color}),
            stroke: new Stroke({color: stroke_color , width: width}),
        }))
    }
}
module.exports.mapSetView = function(map, zoom, feature) {
    // map.getView().setCenter(feature['geometry']['coordinates']);
    if(feature.getGeometry().getType() === 'Point') {
        map.getView().setCenter(feature.getGeometry().getCoordinates());
    }else{
        map.getView().setCenter(extent.getCenter(feature.getGeometry().getExtent()));
    }
    map.getView().setZoom(zoom);
}
module.exports.mapSetViewPoint = function(map, zoom, point) {
    map.getView().setCenter(point);
    map.getView().setZoom(zoom);
}
module.exports.mapSetViewFeature = function(map, zoom, feature) {
    this.transformProjection(feature);
    map.getView().setCenter(feature['geometry']['coordinates']);
    map.getView().setZoom(zoom);
}
module.exports.callLayerOlPolygone = function(geoJsonObject,visibility = false, color = '#ddddddcc'){
    var sourceBddLayer = this.createVectorSourceOl(geoJsonObject);
    var new_layer = new VectorLayer({
        visible: visibility,
        // preload: Infinity,
        zIndex: 150,
        source:sourceBddLayer,
        style : function(feature) {
            var stroke_color = '#033212';
            if(feature.getProperties().color !== undefined) {
                var color_n = feature.getProperties().color;
            }else{
                var color_n = color;
            }
            if(feature.getProperties().stroke_color !== undefined) {
                stroke_color = feature.getProperties().stroke_color;
            }
            return new Style({
                fill: new Fill({color: color_n}),
                stroke: new Stroke({color: stroke_color, width: 2}),
            })
        },
        // style : new Style({
        //     fill: new Fill({color: color}),
        //     stroke: new Stroke({color: '#033212', width: 2}),
        // })
    });
    return(new_layer);
}
module.exports.callLayerOlPolygoneGrad = function(geoJsonObject,visibility = false, max, colorGrad){
    var sourceBddLayer = this.createVectorSourceOl(geoJsonObject);
    var new_layer = new VectorLayer({
        visible: visibility,
        // preload: Infinity,
        zIndex: 150,
        source:sourceBddLayer,
        style : function(feature){
            var stroke_color = '#033212';
            var color_n = '#ddddddcc';
            if (feature.getProperties().cg_value_disp !== undefined){
                var value = feature.getProperties().cg_value_disp;
                var perCent = value * 100 / max ;
                if(value === 0)color_n = '#ddddddcc';
                if(perCent > 0 && perCent <= 10)color_n = 'rgba(246,142,142,0.8)';
                if(perCent >= 11 && perCent <= 20)color_n = 'rgba(245,118,118,0.8)';
                if(perCent >= 21 && perCent <= 30)color_n = 'rgba(245,95,95,0.8)';
                if(perCent >= 31 && perCent <= 40)color_n = 'rgba(246,75,75,0.8)';
                if(perCent >= 41 && perCent <= 50)color_n = 'rgba(246,57,57,0.8)';
                if(perCent >= 51 && perCent <= 60)color_n = 'rgba(246,38,38,0.8)';
                if(perCent >= 61 && perCent <= 70)color_n = 'rgba(243,12,12,0.8)';
                if(perCent >= 71 && perCent <= 80)color_n = 'rgba(248,9,9,0.8)';
                if(perCent >= 81 && perCent <= 90)color_n = 'rgba(189,6,6,0.8)';
                if(perCent >= 91 && perCent <= 100)color_n = 'rgba(128,5,5,0.8)';
                colorGrad.forEach((col)=>{
                    if(perCent > col.value_min && perCent <= col.value_max){
                        color_n = col.back_color;
                        stroke_color = col.border_color;
                    }
                })
            }
            return new Style({
                fill: new Fill({color: color_n}),
                stroke: new Stroke({color: stroke_color, width: 2}),
            })
         }
        // style : new Style({
        //     fill: new Fill({color: color}),
        //     stroke: new Stroke({color: '#033212', width: 2}),
        // })
    });
    return(new_layer);
}
function colorPolygonGrad(feature){
    var stroke_color = '#033212';
    var color_n = '#ddddddcc';
    if (feature.getProperties().cg_value_disp !== undefined){
        var value = feature.getProperties().cg_value_disp;
        var perCent = 25 ;
        if(value === 0)color_n = '#ddddddcc';
        if(perCent > 0 && perCent <= 10)color_n = 'rgba(246,142,142,0.8)';
        if(perCent >= 11 && perCent <= 20)color_n = 'rgba(245,118,118,0.8)';
        if(perCent >= 21 && perCent <= 30)color_n = 'rgba(245,95,95,0.8)';
        if(perCent >= 31 && perCent <= 40)color_n = 'rgba(246,75,75,0.8)';
        if(perCent >= 41 && perCent <= 50)color_n = 'rgba(246,57,57,0.8)';
        if(perCent >= 51 && perCent <= 60)color_n = 'rgba(246,38,38,0.8)';
        if(perCent >= 61 && perCent <= 70)color_n = 'rgba(243,12,12,0.8)';
        if(perCent >= 71 && perCent <= 80)color_n = 'rgba(248,9,9,0.8)';
        if(perCent >= 81 && perCent <= 90)color_n = 'rgba(189,6,6,0.8)';
        if(perCent >= 91 && perCent <= 100)color_n = 'rgba(128,5,5,0.8)';
    }
    // if(feature.getProperties().color !== undefined) {
    //     var color_n = feature.getProperties().color;
    // }else{
    //     var color_n = color;
    // }
    // if(feature.getProperties().stroke_color !== undefined) {
    //     stroke_color = feature.getProperties().stroke_color;
    // }
    return new Style({
        fill: new Fill({color: color_n}),
        stroke: new Stroke({color: stroke_color, width: 2}),
    })
}
module.exports.removeAllLayer = function(map){
    map.getLayers().forEach(function (layer) {
        if (layer !== undefined && layer.getSource() instanceof VectorSource) {
            map.removeLayer(layer);
        }
    });
}
function pointOnVertex(point, vertices){
    var retour = false;
    vertices.forEach((vertex)=>{
        if (point === vertex) {
            retour = true;
        }
    })
    return retour;
}
function pointStringToCoordinates(coordinates){
    return {
        x : coordinates[0],
        y : coordinates[1]
    }
}
module.exports.pointInPolygon = function(point, polygon, pointOnV = true){
    // Transform string coordinates into arrays with x and y values
    // console.log(polygon);
    point = pointStringToCoordinates(point);
    var vertices = [];
    polygon.forEach((vertex)=>{
        vertices.push(pointStringToCoordinates(vertex));
    });
    // Check if the point sits exactly on a vertex
    if ( pointOnV === true && pointOnVertex(point, vertices) === true) {
        return "vertex";
    }

    // Check if the point is inside the polygon or on the boundary
    var intersections = 0;
    var vertices_count = vertices.length;

    for (var i=1; i < vertices_count; i++) {
        var vertex1 = vertices[i-1];
        var vertex2 = vertices[i];
        if (vertex1['y'] === vertex2['y'] && vertex1['y'] === point['y'] && point['x'] > volt.min_max([vertex1['x'], vertex2['x']])['min'] && point['x'] < volt.min_max([vertex1['x'], vertex2['x']])['max']) { // Check if point is on an horizontal polygon boundary
            return "boundary";
        }
        if (point['y'] > volt.min_max([vertex1['y'], vertex2['y']])['min'] && point['y'] <= volt.min_max([vertex1['y'], vertex2['y']])['max'] && point['x'] <= volt.min_max([vertex1['x'], vertex2['x']])['max'] && vertex1['y'] !== vertex2['y']) {
            var xinters = (point['y'] - vertex1['y']) * (vertex2['x'] - vertex1['x']) / (vertex2['y'] - vertex1['y']) + vertex1['x'];
            if (xinters === point['x']) { // Check if point is on the polygon boundary (other than horizontal)
                return "boundary";
            }
            if (vertex1['x'] === vertex2['x'] || point['x'] <= xinters) {
                intersections++;
            }
        }
    }
    // If the number of edges we passed through is odd, then it's in the polygon.
    if (intersections % 2 !== 0) {
        return "inside";
    } else {
        return "outside";
    }
}

module.exports.filtrePointPolygon = function(fileCoordinates, mapProject){
    var tabFeatures = [];
    mapProject.features.forEach((poly, i) => {
        tabFeatures[i] = [];
        if(poly.geometry.type === 'Polygon'){
            poly.geometry.coordinates.forEach((polygon)=>{
                fileCoordinates.forEach((point) => {
                    var result = this.pointInPolygon(point, polygon);
                    if(result === 'inside'){
                        tabFeatures[i].push(point);
                    }
                });
            });
        }else if(poly.geometry.type === 'MultiPolygon'){
            poly.geometry.coordinates.forEach((polygon)=>{
                polygon.forEach((p)=>{
                    fileCoordinates.forEach((point) => {
                        var result = this.pointInPolygon(point, p);
                        if(result === 'inside'){
                            tabFeatures[i].push(point);
                        }
                    });
                })
            });
        }
    });
    return tabFeatures;
}
function getStyleGeoJsonPolygon(nbr,color = '',text = '', type = ''){
    let style = '';
    if(color !== ''){
        style = new Style({
            text: new Text({
                text:text,
                font:'lighter 13px sans-serif',
                stroke: new Stroke({color: 'rgb(78,10,2)', width: 0}),
            }),
            fill: new Fill({color: color+"55"}),
            stroke: new Stroke({color: color, width: 1}),
        });
    }else{
        let color = '';
        let color_stroke = '';
        if(type === 'ept'){
            if(nbr === 0) color = 'rgba(61,137,74,0.6)';
            if(nbr === 1) color = 'rgba(154,77,160,0.6)';
            if(nbr === 2) color = 'rgba(145,234,90,0.6)';
            if(nbr === 3) color = 'rgba(165,33,210,0.6)';
            if(nbr === 4) color = 'rgba(229,123,123,0.6)';
            if(nbr === 5) color = 'rgba(175,162,96,0.6)';
            if(nbr === 6) color = 'rgba(219,166,166,0.6)';
            if(nbr === 7) color = 'rgba(213,175,24,0.6)';
            if(nbr === 8) color = 'rgba(102,184,213,0.6)';
            if(nbr === 9) color = 'rgba(90,57,156,0.6)';
            if(nbr === 10) color = 'rgba(31,138,177,0.6)';
        }else{
            let opacity = 0.6;
            // let opacity = 1;
            if(nbr > 15){
                color = 'rgba(195, 43, 9 , ' + opacity + ')';
                color_stroke = 'rgba(195, 43, 9)';
            }
            if(nbr > 10 && nbr <= 15){
                color = 'rgba(246, 19, 23, ' + opacity + ')';
                color_stroke = 'rgba(246, 19, 23)';
            }
            if(nbr > 5 && nbr <= 10){
                color = 'rgba(242, 100, 24, ' + opacity + ')';
                color_stroke = 'rgba(242, 100, 24)';
            }
            if(nbr > 2 && nbr <= 5){
                color = 'rgba(249, 163, 47, ' + opacity + ')';
                color_stroke = 'rgba(249, 163, 47)';
            }
            if(nbr > 1.5 && nbr <= 2){
                color = 'rgba(249, 199, 79, ' + opacity + ')';
                color_stroke = 'rgba(249, 199, 79)';
            }
            if(nbr > 1 && nbr <= 1.5){
                color = 'rgba(149, 192, 114, ' + opacity + ')';
                color_stroke = 'rgba(149, 192, 114)';
            }
            if(nbr > 0.5 && nbr <= 1){
                color = 'rgba(124, 203, 179, ' + opacity + ')';
                color_stroke = 'rgba(124, 203, 179)';
            }
            if(nbr > 0.2 && nbr <= 0.5){
                color = 'rgba(150, 208, 195, ' + opacity + ')';
                color_stroke = 'rgba(150, 208, 195)';
            }
            if(nbr > 0 && nbr <= 0.2){
                color = 'rgba(175, 212, 211, ' + opacity + ')';
                color_stroke = 'rgba(175, 212, 211)';
            }
            if(nbr <= 0){
                color = 'rgba(242, 247, 247, ' + opacity + ')';
                color_stroke = 'rgba(242, 247, 247)';
            }

            // if(nbr > 15)color = 'rgba(195, 43, 9 ,0.6)';
            // if(nbr > 10 && nbr <= 15)color = 'rgba(246, 19, 23, 0.6)';
            // if(nbr > 5 && nbr <= 10)color = 'rgba(242, 100, 24, 0.6)';
            // if(nbr > 2 && nbr <= 5)color = 'rgba(249, 163, 47, 0.6)';
            // if(nbr > 1.5 && nbr <= 2)color = 'rgba(249, 199, 79, 0.6)';
            // if(nbr > 1 && nbr <= 1.5)color = 'rgba(149, 192, 114, 0.6)';
            // if(nbr > 0.5 && nbr <= 1)color = 'rgba(124, 203, 179, 0.6)';
            // if(nbr > 0.2 && nbr <= 0.5)color = 'rgba(150, 208, 195, 0.6)';
            // if(nbr > 0 && nbr <= 0.2)color = 'rgba(175, 212, 211, 0.6)';
            // if(nbr <= 0)color = 'rgba(242, 247, 247, 0.6)';

        }
        style = new Style({
            fill: new Fill({color: color}),
            // stroke: new Stroke({color: '#000000', width: 1}),
            stroke: new Stroke({color: color_stroke, width: 1}),
        });
    }
    return style;
}
module.exports.callLayerEntOlPolygone = function(geoJsonObject,visibility = false, id){
    this.transformProjection(geoJsonObject);
    if(id === undefined || id === ''){
        id = 'quartier';
    }
    let layer = new VectorLayer({
        visible: visibility,
        id : id,
        // preload: Infinity,
        zIndex: 140,
        style : new Style({
            fill: new Fill({color: 'transparent'}),
            // stroke: new Stroke({color: '#000000', width: 1}),
            stroke: new Stroke({color: '#555555', width: 1}),
        }),
        source: new VectorSource({
            features: (new GeoJSON()).readFeatures(geoJsonObject)
        })
    });
    return(layer);
}
module.exports.callLayerTourizOlPolygone = function(geoJsonObject,visibility = false, id){
    this.transformProjection(geoJsonObject);
    if(id === undefined || id === ''){
        id = 'quartier';
    }
    var irisLayer = new VectorLayer({
        visible: visibility,
        id : id,
        // preload: Infinity,
        zIndex: 140,
        style : function(feature) {
            // console.log(feature)
            let nb = 0;
            if(feature.getProperties().taux_annonces_elan !== undefined){
                nb = feature.getProperties().taux_annonces_elan;
                return getStyleGeoJsonPolygon(nb,'');
            }
            if(feature.getProperties().taux_annonces_arr !== undefined){
                nb = feature.getProperties().taux_annonces_arr;
                return getStyleGeoJsonPolygon(nb,'');
            }
            if(feature.getProperties().zone_name !== undefined){
                let color = feature.getProperties().color;
                return getStyleGeoJsonPolygon(0,color,feature.getProperties().zone_name);
            }
            if(feature.getProperties().iris_color !== undefined){
                let nb = feature.getProperties().iris_color;
                return getStyleGeoJsonPolygon(nb,'',feature.getProperties().iris_name, 'ept');
            }
        },
        source: new VectorSource({
            features: (new GeoJSON()).readFeatures(geoJsonObject)
        })
    });
    return(irisLayer);
}
module.exports.removeAllLayer = function(map){
    map.getLayers().forEach(function (layer) {
        if (layer !== undefined && layer.getSource() instanceof VectorSource) {
            map.removeLayer(layer);
        }
    });
}
module.exports.addOnClick = function(map, id){
    map.getLayers().forEach(function (layer) {
        if (layer !== undefined && layer.getSource() instanceof VectorSource) {
            if(layer.values_.id === id){
                map.on('singleclick', function (evt) {
                    volt.feature = {};
                    map.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
                        let feat = {
                            type : "Feature",
                            properties : feature.getProperties(),
                            geometry : {
                                type : feature.getGeometry().getType(),
                                coordinates : feature.getGeometry().getCoordinates()
                            }
                        }
                        delete feat.properties.geometry;
                        // this.transformProjection(feat, 'EPSG:3857', 'EPSG:4326');
                        // volt.feature = feature.getProperties();
                        volt.feature = feat;
                    });
                });
            }
        }
    });
}