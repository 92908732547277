<template>
  <div class="btns-choice-admin-div">
    <select class="btn-choice-admin" id="select-admin-year" @change="callYear">
      <option v-for="annee in annees" :key="annee" :value="annee">{{ annee }}</option>
    </select>
    <select class="btn-choice-admin" id="select-admin-entity" @change="callUsers">
      <option>Communes</option>
      <option v-for="entity in entities" :key="entity.entity" :value="entity.id+'||'+entity.entity">{{entity.entity}}</option>
    </select>
    <select class="btn-choice-admin" id="select-admin-user" @click="callUserDetails">
      <option value="all">Utilisateurs</option>
      <option v-for="user in users" :key="user" :value="user._id">{{ user.firstName+' '+user.lastName }}</option>
    </select>
    <button class="btn-choice-admin" @click="callProps">Propriétés</button>
  </div>
  <div class="admin-content-div">
    <KpiComponentCO :id = "'kpi-component-admin'"
                    backgroundColor = "#fefefe"
                    color = "#555555"
                    title = "Connexions"
                    :kpis = "analytics"
                    gridCS = "1"
                    gridCE = "21"
                    gridRS = "3"
                    gridRE = "10"
                    gridSize= "10"
                    :entity = "entity_selected"
                    :year = "year"
                    :map_filter = "map_filter"
    />
  </div>
</template>

<script>
import admin from "@/services/admin";
import volt from "@/volt";
import KpiComponentCO from "@/components/KpiComponentCO";
export default {
  name: "adminBoard",
  props: {
    user : Object,
    env : Object,
    entities : Object,
    year : String,
    map_filter : Number
  },
  components: {
    KpiComponentCO
  },
  data(){
    return{
      users: [],
      entity_selected: '',
      user_selected: {},
      analytics: [
        {
          title: "Connexions",
          details: [
            {
              title: "Connexions",
              value: 0,
              link: "Connexions",
              style: {backgroundColor: "#fefefe", color: "#555555", gridCS: "1", gridCE: "21", visuel: "jauge"},
              evolution: 0
            }
          ],
          gridCS: "1",
          gridCE: "13",
          size: "12"
        }
      ],
      annees: ['2024']
    }
  },
  mounted() {
  },
  methods: {
    async callUsers(e){
      this.entity_selected = e.target.value.split('||')[1];
      const usersTab = await admin.getUsersEntity(e.target.value.split('||')[0], this.entity_selected, volt.tak('select-admin-year').value)
      this.users = usersTab['users'];
      this.analytics.value = usersTab['analytic'];
    },
    async callYear(e){
      this.entity_selected = volt.tak('select-admin-entity').value.split('||')[1];
      const usersTab = await admin.getUsersEntity(volt.tak('select-admin-entity').value.split('||')[0], this.entity_selected, e.target.value)
      this.users = usersTab['users'];
      this.analytics.value = usersTab['analytic'];
    },
    async callUserDetails(e){
      this.user_selected = await admin.getUserDetails(e.target.value, this.entity_selected, this.year)
    },
    callProps() {
      console.log(this.user);
      console.log(this.env);
      console.log(this.entities);
      console.log(this.year);
      console.log(admin);
      console.log(volt);
    }
  }
}
</script>

<style scoped>
.admin-content-div{
  grid-column-start: 1;
  grid-column-end: 21;
  grid-row-start: 3;
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-gap: 1px;
  padding: 20px;
  height: 100%;
  max-height: 100%;
  background-color: #fefefe;
}
.btns-choice-admin-div{
  position: sticky;
  grid-column-start: 1;
  grid-column-end: 21;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}
.btn-choice-admin{
  z-index: 1000;
  width: 200px;
  background-color: #d6d6d6;
  color: #343434;
  border: none;
  padding: 10px 20px;
  text-align: start;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.3s;
}
</style>