<template>
  <div v-if="Object.keys(annonce).length > 0" class="conformite-annonce"  @click="closePopupWindews">
    <div class="header-top-div">
      <div class="header-top-value-elan">DONNEES ELAN</div>
      <div class="header-top-value-decla">DECLARATION</div>
      <div class="header-top-value-conformite">CONTROLES</div>
      <div class="header-top-value-comment">COMMENTAIRES</div>
    </div>
    <div class="header-lat-div">
      <div class="header-lat-value">Plateforme :</div>
      <div class="header-lat-value">Numéro d'enregistrement :</div>
      <div class="header-lat-value">Nom du loueur :</div>
      <div class="header-lat-value">Adresse du local :</div>
      <div class="header-lat-value">Statut résidence :</div>
      <div class="header-lat-value">Référence CU :</div>
      <div class="header-lat-value">Statut CU :</div>
      <div class="header-lat-value">Date de validité :</div>
      <div class="header-lat-value"><span style="font-size: 12px;text-align: left">Logements sociaux identifiés à cette adresse :</span></div>
      <div class="header-lat-value"><span style="font-size: 12px;text-align: left">Nombre de jours de location :<br>(contrôle des 120 jours)</span></div>
    </div>
    <div class="values-elan-div">
      <div class="values-elan-value no-border-right">{{annonce.plateforme}}
        <img v-if="annonce.plateforme === 'Airbnb'" class="link-airbnb" src="airbnb_logo_icon_170606.png" alt="logo airbnb" @click="openAirbnb"/>
        <img v-if="annonce.plateforme === 'Booking'" class="link-booking" src="Booking-Com-Logo.png" alt="logo airbnb" @click="openBooking"/>
        <img v-if="annonce.plateforme === 'Abritel'" class="link-abritel" src="abritel-homeaway-logo.png" alt="logo airbnb" @click="openAbritel"/>
      </div>
      <span style="display: none">{{i=1}}</span>
      <div v-for="elan_value in elan_values" :key="'field_'+elan_value.value+'_'+i" class="values-elan-value" :style="'background-color:'+color[elan_value.from]">
        <div v-if="elan_value.value !== ''">{{ elan_value.value }}</div>
        <div v-else class="empty-value">---</div>
        <span style="display: none">{{i++}}</span>
      </div>
    </div>
    <div class="values-decla-div">
      <div class="values-decla-value no-border-right"></div>
      <span style="display: none">{{c=1}}</span>
      <div v-for="decla_value in decla_values" :key="'field_'+decla_value.value+'_'+c" class="values-decla-value" :style="'background-color:'+color[decla_value.from]">
        <div v-if="decla_value.value !== ''">{{ decla_value.value }}</div>
        <div v-else class="empty-value">---</div>
        <span style="display: none">{{c++}}</span>
      </div>
    </div>
    <div class="values-conformite-div">
      <div class="values-conformite-value no-border-right" style="font-size: 10px;justify-content: end">(*) modifié par un agent</div>
      <span style="display: none">{{c=1}}</span>
      <div v-for="conformite_value in conformites_value" :key="'field_'+conformite_value.value+'_'+c" class="values-conformite-value">
        <SelectConformite :conformite="conformite_value" :annonces="ids" :annonce="annonce._id" :entity="entity" :user="user" />
        <span style="display: none">{{c++}}</span>
      </div>
    </div>
    <div class="values-comment-div">
      <div class="values-comment-value" :style="'background-color:'+color_elan "></div>
      <span style="display: none">{{c=1}}</span>
      <div v-for="conformite_comm in conformites_comm" :key="'field_'+conformite_comm.value+'_'+c" class="values-comment-value">
        <textarea rows="2" class="comment-textarea" :id="conformite_comm.field" :value="conformite_comm.value"/>
        <span style="display: none">{{c++}}</span>
      </div>
    </div>
    <div class="div-legend"><div class="div-legend-source">Sources :</div>
      <div class="div-legend-elan">ELAN</div>
      <div class="div-legend-ne">NE</div>
      <div class="div-legend-cu">CU</div>
      <div class="div-legend-traitement">Autre</div></div>
    <div v-if="year > 2021" class="div-valid-values-comment"><button class="btn-valid-values-comment" @click="saveComm">Enregistrer</button></div>
  </div>
  <div v-else-if="ids && ids.length > 0" class="conformite-annonce" @click="closePopupWindews">
    <div class="msg-alert-info">Attention Les modifications apportées ici impacteront les {{ids.length}} annonces de la liste.</div>
    <div class="header-top-div-multi">
      <div class="header-top-value-conformite-multi">CONTROLES</div>
      <div class="header-top-value-comment-multi">COMMENTAIRES</div>
    </div>
    <div class="header-lat-div-multi">
      <div class="header-lat-value">Numéro d'enregistrement :</div>
      <div class="header-lat-value">Nom du loueur :</div>
      <div class="header-lat-value">Adresse du local :</div>
      <div class="header-lat-value">Statut résidence :</div>
      <div class="header-lat-value">Référence CU :</div>
      <div class="header-lat-value">Statut CU :</div>
      <div class="header-lat-value">Date de validité :</div>
      <div class="header-lat-value"><span style="font-size: 12px;text-align: left">Logements sociaux identifiés à cette adresse :</span></div>
      <div class="header-lat-value"><span style="font-size: 12px;text-align: left">Nombre de jours de location :<br>(contrôle des 120 jours)</span></div>
    </div>
    <div v-if="year > 2021" class="values-conformite-div-multi">
      <span style="display: none">{{c=1}}</span>
      <div v-for="conformite_value in conformites_value" :key="'field_'+conformite_value.value+'_'+c" class="values-conformite-value">
        <SelectConformite :conformite="conformite_value" :annonces="ids" :annonce="annonce._id" :entity="entity" :user="user" />
        <span style="display: none">{{c++}}</span>
      </div>
    </div>
    <div v-if="year > 2021" class="values-comment-div-multi">
      <span style="display: none">{{c=1}}</span>
      <div v-for="conformite_comm in conformites_comm" :key="'field_'+conformite_comm.value+'_'+c" class="values-comment-value">
        <textarea rows="2" class="comment-textarea" :id="conformite_comm.field+'_multi'" :value="conformite_comm.value"/>
        <span style="display: none">{{c++}}</span>
      </div>
    </div>
    <div  v-if="year > 2021" class="div-valid-values-comment-multi"><button class="btn-valid-values-comment" @click="saveComm">Enregistrer</button></div>
  </div>
  <div v-else class="conformite-annonce-loader">
    <div>
      <KpiLoader title="pop-annonce"/>
    </div>
  </div>
</template>

<script>
import SelectConformite from "@/elements/SelectConformite";
import volt from "@/volt";
import KpiLoader from "@/components/KpiLoader";
export default {
  name: "ConformiteAnnonce",
  components: {KpiLoader, SelectConformite},

  props:{
    annonce : Object,
    annonces : Object,
    entity : String,
    user : {},
    ids : Object,
    year : String
  },
  methods:{
    openAirbnb(){
      let link = 'https://www.airbnb.fr/s/'+this.annonce.feature.properties.adr_elan.replace(/ /g,'-')+'--France/homes?tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&channel=EXPLORE&query='+this.annonce.feature.properties.adr_elan+'%2C%20France&ne_lat='+this.annonce.feature.geometry.coordinates[1]+'&ne_lng='+this.annonce.feature.geometry.coordinates[0]+'&sw_lat='+this.annonce.feature.geometry.coordinates[1]+'&sw_lng='+this.annonce.feature.geometry.coordinates[0];
      window.open(link, '_blank');
    },
    openBooking(){
      let link = 'https://www.booking.com/searchresults.fr.html?nflt=distance%3D500&ss='+this.annonce.feature.properties.adr_elan+'+France#map_opened'
      window.open(link, '_blank');
    },
    openAbritel(){
      let link = 'https://www.abritel.fr/search?destination='+this.annonce.feature.properties.adr_elan+'%20France&latLong='+this.annonce.feature.geometry.coordinates[1]+'%2C'+this.annonce.feature.geometry.coordinates[0]+'&mapBounds='+(this.annonce.feature.geometry.coordinates[1]-0.00001)+'%2C'+(this.annonce.feature.geometry.coordinates[0]-0.00001)+'&mapBounds='+(this.annonce.feature.geometry.coordinates[1]+0.00001)+'%2C'+(this.annonce.feature.geometry.coordinates[0]+0.00001)+'&sort=RECOMMENDED'
      window.open(link, '_blank');
    },
    closePopupConf(){
      volt.taks('.comment-textarea','',false).forEach((el)=>{
        el.style.height = '';
      });
      volt.taks('.value-select-field','',false).forEach((el)=>{
        el.style.border = '';
      });
      volt.taks('.select-choices-conform-field','',false).forEach((el)=>{
        el.style.display = '';
      });
      volt.taks('.fa-pen-alt','',false).forEach((el)=>{
        el.style.color = '';
      });
    },
    closePopupWindews(e){
      if(!e.target.classList.contains('fa-pen-alt')){
        this.closePopupConf();
      }
    },
    saveComm() {
      let fields = {};
      if (this.ids.length > 0) {
        this.conformites_comm.forEach((comm) => {
          fields[comm.field] = volt.tak(comm.field + '_multi').value;
        })
      } else {
        this.conformites_comm.forEach((comm) => {
          fields[comm.field] = volt.tak(comm.field).value;
        })
      }
      let data = {
        fields: fields,
        annonce: this.annonce._id,
        annonces: this.ids,
        from: this.user
      }
      if (data.annonces.length > 0) {
        if (confirm('Voulez-vous vraiment modifier les commentaires des ' + data.annonces.length + ' annonces ?')) {
          volt.post('obs/comments/update', data, '', this.entity).then((response) => {
            console.log(response);
          });
        }
      } else {
        volt.post('obs/comments/update', data, '', this.entity).then((response) => {
          console.log(response);
        });
      }
    }
  },
  data(){
    return{
      statut_residence : "Non communiqué",
      log_soc : "Non",
      elan_values : [],
      decla_values : [],
      conformites_value : [],
      conformites_comm : [],
      color : {
          CU : 'rgba(177,248,203,0.33)',
          NE : 'rgba(178,198,243,0.33)',
          ELAN : 'rgba(227,192,244,0.33)',
          AUTRE : 'rgba(204,200,200,0.33)'
      },
      color_elan : 'rgba(227,192,244,0.33)',
      color_autre : 'rgba(204,200,200,0.33)'
    }
  },
  watch:{
    annonce(){
      if(Object.keys(this.annonce).length > 0) {
        this.elan_values = [];
        this.decla_values = [];
        if (this.annonce.feature.properties.res_ppale === 'oui' && (this.annonce.feature.properties.res_sec === 'non' || this.annonce.feature.properties.res_sec === '')) {
          this.statut_residence = 'Résidence principale';
        } else if (this.annonce.feature.properties.res_sec === 'oui' && (this.annonce.feature.properties.res_ppale === 'non' || this.annonce.feature.properties.res_ppale === '')) {
          this.statut_residence = 'Résidence secondaire';
        } else {
          this.statut_residence = "Non communiqué";
        }
        if (this.annonce.feature.properties.log_soc === false) {
          this.log_soc = 'Non';
        } else {
          this.log_soc = 'Oui';
        }
        this.elan_values = [
          {
            value: this.annonce.feature.properties.id_num,
            from: 'ELAN'
          },
          {
            value: this.annonce.feature.properties.nom_loueur,
            from: 'ELAN'
          },
          {
            value: this.annonce.feature.properties.adr_elan,
            from: 'ELAN'
          },
          {
            value: this.statut_residence,
            from: 'ELAN'
          },
          {
            value: '',
            from: 'AUTRE'
          },
          {
            value: '',
            from: 'AUTRE'
          },
          {
            value: '',
            from: 'AUTRE'
          },
          {
            value: '',
            from: 'AUTRE'
          },
          {
            value: this.annonce.feature.properties.nuit_t1,
            from: 'ELAN'
          },
        ];
        this.decla_values = [
          {
            value: this.annonce.feature.properties.affichage_ne,
            from: 'NE'
          },
          {
            value: this.annonce.feature.properties.affichage_nom_loueur,
            from: 'NE'
          },
          {
            value: this.annonce.feature.properties.affichage_adresse_loueur,
            from: 'NE'
          },
          {
            value: this.annonce.feature.properties.affichage_statut_residence,
            from: 'NE'
          },
          {
            value: this.annonce.feature.properties.affichage_cu,
            from: 'CU'
          },
          {
            value: this.annonce.feature.properties.affichage_statut_cu,
            from: 'CU'
          },
          {
            value: this.annonce.feature.properties.affichage_date_validite_cu,
            from: 'CU'
          },
          {
            value: this.log_soc,
            from: 'AUTRE'
          },
          {
            value: this.annonce.feature.properties.affichage_rp_120j,
            from: 'AUTRE'
          },
        ];
        if(this.year > 2021) {
          this.conformites_value = [
            {
              field: 'up_conformite_ne',
              origin: this.annonce.feature.properties.conformite_ne,
              value: this.annonce.updates.up_conformite_ne,
              details: this.annonce.updates.details_up_conformite_ne,
            },
            {
              field: 'up_conformite_nom_loueur',
              origin: this.annonce.feature.properties.conformite_nom_loueur,
              value: this.annonce.updates.up_conformite_nom_loueur,
              details: this.annonce.updates.details_up_conformite_nom_loueur,
            },
            {
              field: 'up_conformite_adresse_loueur',
              origin: this.annonce.feature.properties.conformite_adresse_loueur,
              value: this.annonce.updates.up_conformite_adresse_loueur,
              details: this.annonce.updates.details_up_conformite_adresse_loueur,
            },
            {
              field: 'up_conformite_statut_residence',
              origin: this.annonce.feature.properties.conformite_statut_residence,
              value: this.annonce.updates.up_conformite_statut_residence,
              details: this.annonce.updates.details_up_conformite_statut_residence,
            },
            {
              field: 'up_conformite_cu',
              origin: this.annonce.feature.properties.conformite_cu,
              value: this.annonce.updates.up_conformite_cu,
              details: this.annonce.updates.details_up_conformite_cu,
            },
            {
              field: 'up_conformite_statut_cu',
              origin: this.annonce.feature.properties.conformite_statut_cu,
              value: this.annonce.updates.up_conformite_statut_cu,
              details: this.annonce.updates.details_up_conformite_statut_cu,
            },
            {
              field: 'up_conformite_date_validite_cu',
              origin: this.annonce.feature.properties.conformite_date_validite_cu,
              value: this.annonce.updates.up_conformite_date_validite_cu,
              details: this.annonce.updates.details_up_conformite_date_validite_cu,
            },
            {
              field: 'up_conformite_log_soc',
              origin: this.annonce.feature.properties.conformite_log_soc,
              value: this.annonce.updates.up_conformite_log_soc,
              details: this.annonce.updates.details_up_conformite_log_soc,
            },
            {
              field: 'up_conformite_rp_120j',
              origin: this.annonce.feature.properties.conformite_rp_120j,
              value: this.annonce.updates.up_conformite_rp_120j,
              details: this.annonce.updates.details_up_conformite_rp_120j,
            },
          ];
          this.conformites_comm = [
            {
              field: 'com_conformite_ne',
              value: this.annonce.updates.com_conformite_ne,
              details: this.annonce.updates.details_com_conformite_ne,
            },
            {
              field: 'com_conformite_nom_loueur',
              value: this.annonce.updates.com_conformite_nom_loueur,
              details: this.annonce.updates.details_com_conformite_nom_loueur,
            },
            {
              field: 'com_conformite_adresse_loueur',
              value: this.annonce.updates.com_conformite_adresse_loueur,
              details: this.annonce.updates.details_com_conformite_adresse_loueur,
            },
            {
              field: 'com_conformite_statut_residence',
              value: this.annonce.updates.com_conformite_statut_residence,
              details: this.annonce.updates.details_com_conformite_statut_residence,
            },
            {
              field: 'com_conformite_cu',
              value: this.annonce.updates.com_conformite_cu,
              details: this.annonce.updates.details_com_conformite_cu,
            },
            {
              field: 'com_conformite_statut_cu',
              value: this.annonce.updates.com_conformite_statut_cu,
              details: this.annonce.updates.details_com_conformite_statut_cu,
            },
            {
              field: 'com_conformite_date_validite_cu',
              value: this.annonce.updates.com_conformite_date_validite_cu,
              details: this.annonce.updates.details_com_conformite_date_validite_cu,
            },
            {
              field: 'com_conformite_log_soc',
              value: this.annonce.updates.com_conformite_log_soc,
              details: this.annonce.updates.details_com_conformite_log_soc,
            },
            {
              field: 'com_conformite_rp_120j',
              value: this.annonce.updates.com_conformite_rp_120j,
              details: this.annonce.updates.details_com_conformite_rp_120j,
            },
          ]
        }
      }
    },
    annonces(){
      if(this.annonces.length > 0) {
        this.conformites_value = [
          {
            field: 'up_conformite_ne',
            value: '',
          },
          {
            field: 'up_conformite_nom_loueur',
            value: '',
          },
          {
            field: 'up_conformite_adresse_loueur',
            value: '',
          },
          {
            field: 'up_conformite_statut_residence',
            value: '',
          },
          {
            field: 'up_conformite_cu',
            value: '',
          },
          {
            field: 'up_conformite_statut_cu',
            value: '',
          },
          {
            field: 'up_conformite_date_validite_cu',
            value: '',
          },
          {
            field: 'up_conformite_log_soc',
            value: '',
          },
          {
            field: 'up_conformite_rp_120j',
            value: '',
          },
        ];
        this.conformites_comm = [
          {
            field: 'com_conformite_ne',
            value: '',
          },
          {
            field: 'com_conformite_nom_loueur',
            value: '',
          },
          {
            field: 'com_conformite_adresse_loueur',
            value: '',
          },
          {
            field: 'com_conformite_statut_residence',
            value: '',
          },
          {
            field: 'com_conformite_cu',
            value: '',
          },
          {
            field: 'com_conformite_statut_cu',
            value: '',
          },
          {
            field: 'com_conformite_date_validite_cu',
            value: '',
          },
          {
            field: 'com_conformite_log_soc',
            value: '',
          },
          {
            field: 'com_conformite_rp_120j',
            value: '',
          },
        ]
      }
    }
  }
}
</script>

<style scoped>
.link-airbnb{
  width: 60px;
  cursor: pointer;
}
.link-booking{
  width: 70px;
  cursor: pointer;
}
.link-abritel{
  width: 70px;
  cursor: pointer;
}
.conformite-annonce{
  width: 98%;
  height: 98%;
  background-color: #eceaea;
  border-radius: 0 5px 0 0;
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-gap: 0;
  grid-template-rows: repeat(15, 1fr);
  padding: 5px 1% 5px 1%;
  color: #666666;
}
.conformite-annonce-loader{
  width: 100%;
  height: 100%;
  background-color: #eceaea;
  /*background-color: #ed5858;*/
  border-radius: 0 5px 0 0;
  padding: 5px 1% 5px 1%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-top-div{
  grid-column-start: 5;
  grid-column-end: 21;
  grid-row-start: 1;
  grid-row-end: 2;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-gap: 1px;
  grid-template-rows: repeat(1, 1fr);
}
.header-top-div-multi{
  grid-column-start: 8;
  grid-column-end: 21;
  grid-row-start: 3;
  grid-row-end: 4;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-gap: 1px;
  grid-template-rows: repeat(1, 1fr);
}
.header-lat-div{
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 15;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0;
  grid-template-rows: repeat(10, 1fr);
}
.header-lat-div-multi{
  grid-column-start: 1;
  grid-column-end: 8;
  grid-row-start: 4;
  grid-row-end: 17;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0;
  grid-template-rows: repeat(10, 1fr);
}
.header-lat-value{
  font-size: 13px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 5px;
  justify-content: start;
  border-bottom: solid 1px #7a7a7a55;
  background-color: v-bind(color_autre);
}
.header-top-value-elan {
  grid-column-start: 1;
  grid-column-end: 6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #7a7a7a55;
  font-weight: 600;
  font-size: 15px;
}
.values-elan-div{
  grid-column-start: 5;
  grid-column-end: 10;
  grid-row-start: 2;
  grid-row-end: 15;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0;
  grid-template-rows: repeat(10, 1fr);
}
.values-elan-value{
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 5px;
  text-align: left;
  justify-content: space-between;
  border-bottom: solid 1px #7a7a7a55;
  border-right: solid 1px #7a7a7a55;
}
.header-top-value-decla{
  grid-column-start: 6;
  grid-column-end: 11;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #7a7a7a55;
  font-weight: 600;
  font-size: 15px;
}
.values-decla-div{
  grid-column-start: 10;
  grid-column-end: 15;
  grid-row-start: 2;
  grid-row-end: 15;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0;
  grid-template-rows: repeat(10, 1fr);

}
.values-decla-value{
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 5px;
  text-align: left;
  justify-content: start;
  border-bottom: solid 1px #7a7a7a55;
  border-right: solid 1px #7a7a7a55;
}
.header-top-value-conformite{
  grid-column-start: 11;
  grid-column-end: 14;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #7a7a7a55;
  font-weight: 600;
  font-size: 15px;
}
.header-top-value-conformite-multi{
  grid-column-start: 1;
  grid-column-end: 8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #7a7a7a55;
  font-weight: 600;
  font-size: 15px;
}
.values-conformite-div{
  grid-column-start: 15;
  grid-column-end: 18;
  grid-row-start: 2;
  grid-row-end: 15;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0;
  grid-template-rows: repeat(10, 1fr);
}
.values-conformite-div-multi{
  grid-column-start: 8;
  grid-column-end: 13;
  grid-row-start: 4;
  grid-row-end: 17;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0;
  grid-template-rows: repeat(10, 1fr);
}
.values-conformite-value{
  font-size: 13px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding-left: 5px;
  border-bottom: solid 1px #7a7a7a55;
  border-right: solid 1px #7a7a7a55;
  background-color: v-bind(color_autre);
}
.header-top-value-comment{
  grid-column-start: 14;
  grid-column-end: 17;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #7a7a7a55;
  font-weight: 600;
  font-size: 15px;
}
.header-top-value-comment-multi{
  grid-column-start: 8;
  grid-column-end: 17;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #7a7a7a55;
  font-weight: 600;
  font-size: 15px;
}
.values-comment-div{
  grid-column-start: 18;
  grid-column-end: 21;
  grid-row-start: 2;
  grid-row-end: 15;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0;
  grid-template-rows: repeat(10, 1fr);
  overflow: auto;
}
.values-comment-div-multi{
  grid-column-start: 13;
  grid-column-end: 21;
  grid-row-start: 4;
  grid-row-end: 17;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0;
  grid-template-rows: repeat(10, 1fr);
  overflow: auto;
}
.div-valid-values-comment{
  padding: 10px 0 0 0;
  grid-column-start: 18;
  grid-column-end: 21;
  grid-row-start: 15;
  grid-row-end: 15;
}
.div-valid-values-comment-multi{
  padding: 10px 0 0 0;
  grid-column-start: 18;
  grid-column-end: 21;
  grid-row-start: 15;
  grid-row-end: 15;
}
.btn-valid-values-comment{
  padding: 5px;
  font-size: 15px;
  background-color: #f3b61b;
  border: none;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
}
.btn-valid-values-comment:active{
  box-shadow: inset 1px 1px 5px #7a7a7a;
}
.values-comment-value {
  font-size: 13px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #7a7a7a55;
  border-right: solid 1px #7a7a7a55;
}
.no-border-right{
  border-right: none;
  background-color: v-bind(color_elan);
}
.empty-value{
  width: 100%;
  text-align: center;
}
.comment-textarea {
  border: none;
  padding: 5px;
  font-size: 10px;
  width: 100%;
  max-width: 100%;
  box-shadow: inset 1px 1px 5px #7a7a7a;
  background-color: #efeeee;
  resize: none;
}
.comment-textarea:hover{
  resize: vertical;
}
.div-legend{
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  grid-column-start: 1;
  grid-column-end: 15;
  grid-row-start: 15;
  grid-row-end: 15;
}
.div-legend-source{
  width: 100px;
  padding: 5px;
  text-align: right;
}
.div-legend-cu{
  width: 100px;
  padding: 5px;
  background-color: v-bind(color['CU']);
}
.div-legend-ne{
  width: 100px;
  padding: 5px;
  background-color: v-bind(color['NE']);
}
.div-legend-elan{
  width: 100px;
  padding: 5px;
  background-color: v-bind(color['ELAN']);
}
.div-legend-traitement{
  width: 100px;
  padding: 5px;
  background-color: v-bind(color['AUTRE']);
}
.msg-alert-info{
  grid-column-start: 2;
  grid-column-end: 20;
  grid-row-start: 1;
  grid-row-end: 2;
  background-color: #f3b61b;
  color: #ffffff;
  font-size: 15px;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
}
</style>