const html2PDF = require('jspdf-html2canvas');

module.exports.generatePdf = async function () {
    const pages = document.querySelectorAll(".kpi-content-div")

    return await html2PDF(pages, {
        jsPDF: {
            unit: 'px',
            format: 'a4',
            orientation: "portrait"
        },
        imageQuality: 1,
        // watermark: {
        //     src: urlImageBase64,
        //     handler({pdf, imgNode, pageNumber}) {
        //         if (pageNumber === 1) {
        //             pdf.addImage(imgNode, "JPEG", pdf.getPageWidth() / 2 - 60, 15, 120, 30)
        //         }
        //     }
        // },
        autoResize: true,
    })
}
module.exports.generatePdfElement = async function (dom_element) {
        // const pages = document.querySelectorAll(".page")
        // pages[0].style.paddingTop = "100px"
        // const checkMarks = document.querySelectorAll(".check-mark-required")
        // checkMarks.forEach(check => check.style.boxShadow = "none")

        return await html2PDF(dom_element, {
            jsPDF: {
                unit: 'px',
                format: 'a4',
                orientation: "l",
            },
            imageQuality: 1,
            autoResize: true
        })
}