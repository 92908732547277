/* eslint-disable */
const volt = require("./volt");
const {Map, View, Overlay} =require("ol");
const {defaults, ZoomSlider} =require("ol/control");
const {Tile, Vector : VectorLayer} =require("ol/layer");
const {OSM, Vector : VectorSource} =require("ol/source");
const {Style, Circle, Fill, Stroke, Text, Icon} =require("ol/style");
const olFunction = require("@/olFunction");
const {transform} = require("ol/proj");

module.exports.mapOlMin= '';
module.exports.callMap= function(el, center, zoom, m) {

    // //creation de l'overlay menu
    // const menuDiv = document.createElement("div");
    // menuDiv.classList.add('menu-map-ol');
    // menuDiv.id = 'menu-map-'+ el;
    // const menuChoice = document.createElement("div");
    // menuChoice.classList.add('menu-choices-map-ol');
    // // menuChoice.innerText = 'huhreoivhnhervpknrepkhjnvk';
    // const closeMenu = document.createElement("div");
    // closeMenu.classList.add('menu-close-map-ol');
    // closeMenu.innerText = 'X';
    // closeMenu.setAttribute('data-menu', el);
    // menuDiv.appendChild(closeMenu);
    // menuDiv.appendChild(menuChoice);
    // const menu = new Overlay({
    //     id : 'overlay-menu-map-'+el,
    //     element : menuDiv,
    //     position : [0],
    //     autoPan: {
    //         animation: {
    //             duration: 250,
    //         },
    //     },
    // });
    // const menuBurger = document.createElement('div');
    // menuBurger.classList.add('burger-menu-map');
    // menuBurger.innerHTML = '<i class="fas fa-bars" style="font-size: 30px"></i>';
    // menuBurger.setAttribute('data-menu', el);
    // const burger = new Overlay({
    //     id : 'overlay-burger-menu-map-'+el,
    //     element : menuBurger,
    //     position : [0]
    // });
////////////////////////////////////////////////////////////////////////////////////////////

    if(zoom === undefined)zoom = 14;
    let point = '';
    if(center === undefined || center === ''){
        point = {
            type: "FeatureCollection",
            features: [
                {
                    type:"Feature",
                    geometry:{
                        type:"Point",
                        coordinates: [2.3470081, 48.8590081]
                    }
                }
            ]
        };
    }else{
        point = {
            type: "FeatureCollection",
            features: [center]
        };
    }
    olFunction.transformProjection(point);
    if(m === undefined || m === '') {
        this.mapOlMin = new Map({
            target: 'map-' + el,
            layers: [
                new Tile
                ({
                    source: new OSM(),
                }),
            ],
            view: new View({
                center: point['features'][0]['geometry']['coordinates'],
                zoom: zoom,
            }),
            controls: defaults({
                attribution: false,
                zoom: false,
                rotate: false,
            }),
            // overlays: [menu, burger],
        });
        // console.log(this.mapOlMin.getOverlays());
        // this.mapOlMin.getOverlayById('overlay-menu-map-'+el).style.left='50px';
    }else{
        this.mapOlMin = m;
        m.getView().setCenter(point['features'][0]['geometry']['coordinates']);
        m.getView().setZoom(zoom);
        m.setTarget('map-'+el);
        // console.log(m.getControls());
        m.getControls().removeAt(0);
        m.getControls().removeAt(0);
        m.getControls().removeAt(0);
    }
    volt.maps[el] = module.exports.mapOlMin;
    // if(this.mapOlMin.getSize() !== undefined){
    //     setTimeout(()=>{
    //         menuDiv.style.left = (this.mapOlMin.getSize()[0] - menuDiv.offsetWidth) + 'px';
    //         menuDiv.style.height = this.mapOlMin.getSize()[1] + 'px';
    //         menuDiv.style.display = 'none';
    //         menuBurger.style.left = (this.mapOlMin.getSize()[0] - 60) + 'px';
    //     },100)
    //     menuBurger.addEventListener('click',function(){
    //         volt.annonce = '';
    //         menuDiv.style.display = '';
    //         this.style.display = 'none';
    //     });
    //     closeMenu.addEventListener('click',function(){
    //         volt.annonce = '';
    //         menuDiv.style.display = 'none';
    //         menuBurger.style.display = '';
    //     });
    // }


    module.exports.mapOlMin.on('pointerdrag', function(evt){
        volt.annonce = '';
    });
    module.exports.mapOlMin.on('pointermove', function(evt){
        var hit = module.exports.mapOlMin.forEachFeatureAtPixel(evt.pixel, function(feature){
            // console.log(feature);
            // if (module.exports.selected !== null){
            //     module.exports.selected.setStyle(undefined);
            //     module.exports.selected = null;
            // }
            // module.exports.selected = feature;
            // feature.setStyle(olFunction.styleOnClick);
            // if(feature.getGeometry().getType() == 'Point'){
            //     feature.setStyle(PointHover);
            // }
            return true;
        });
        if(hit){
            volt.tak(module.exports.mapOlMin.getTarget()).style.cursor =  'pointer';
        }else{
            volt.tak(module.exports.mapOlMin.getTarget()).style.cursor =  '';
        }
    });


    this.mapOlMin.getLayers().forEach(function (layer) {
        if (layer !== undefined && layer.getSource() instanceof VectorSource) {
            // console.log(layer.values_);
        }
    });
    module.exports.mapOlMin.on('singleclick', function (evt) {
        volt.annonce = '';
        module.exports.mapOlMin.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
            if(layer.values_.id === 'quartier'){
                volt.feature = {};
                let feat = {
                    type : "Feature",
                    properties : feature.getProperties(),
                    geometry : {
                        type : feature.getGeometry().getType(),
                        coordinates : feature.getGeometry().getCoordinates()
                    }
                }
                delete feat.properties.geometry;
                olFunction.transformProjection(feat, 'EPSG:3857', 'EPSG:4326');
                // volt.feature = feature.getProperties();
                volt.feature = feat;
            }else if(layer.values_.id === 'inactif'){
                // volt.annonce = '';
            }else{
                // volt.annonce = feature.getProperties()._id;
                setTimeout(()=>{volt.annonce = feature.values_.features[0].getProperties()._id},200);
            }
        });
    });

    module.exports.mapOlMin.on('dblclick', function (evt) {
        module.exports.mapOlMin.forEachFeatureAtPixel(evt.pixel, function (feature) {
            console.log(feature);
        });
    });

    return module.exports.mapOlMin;
}